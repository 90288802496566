import { Box, Chip, SxProps, Typography } from "@mui/material";
import { TFunction } from "i18next";
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';

interface StatusChipProps<StatusType> {
    title?: string;
    status: StatusType;
    attendeeStatus: string;
    date?: string;
    t: TFunction<"translation", undefined>;
    dueDate: Date;
    getStatusProps: (status: StatusType, dueDate: Date) => { icon: JSX.Element; color: string };
    sx?: SxProps;
    titleSx?: SxProps;
}

export function StatusChip<StatusType extends string>({ title, status, t, dueDate, getStatusProps, sx, titleSx, attendeeStatus }: StatusChipProps<StatusType>): JSX.Element {
    const { icon, color } = getStatusProps(status, dueDate);

    return (
        <Chip
            title={` ${title ? `${title} - ` : ''} ${t(`${status}`)}`}
            icon={icon}
            label={
                <>
                    {title && (
                        <Box sx={{display:"flex"}}>
                        <Typography
                            fontSize={11}
                            fontWeight={500}
                            sx={{ color: "#333", ...titleSx }}
                            lineHeight={.8}
                            marginTop={1}
                            >
                            {t(`${title}`) + " "} 
                        </Typography>
                         
                        {
                            attendeeStatus !== null && attendeeStatus !== "" &&
                            <Typography
                                fontSize={11}
                                fontWeight={500}
                                lineHeight={.8}
                                marginTop={1}> - {t(`${attendeeStatus}`)}</Typography>
                        }
                        </Box>
                    )}
                    {status &&
                        <Typography
                            fontSize={11}
                            fontWeight={500}
                            sx={{
                                color: title ? "#777" : "#333", display: "block", whiteSpace: "normal"
                            }}
                        >
                            {t(`${status}`)}
                        </Typography>
                    }
                </>
            }
            data-test-id={`status - chip - ${title ?? status} `}
            sx={{ bgcolor: color, height: title ? "36px" : "26px", ...sx }}
        />
    );
}
