import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";

import App from "../App";
import ForgotPasswordForm from "../pages/User/PasswordReset/ForgotPasswordForm";
import ResetPasswordForm from "../pages/User/PasswordReset/ResetPasswordForm";
import RequireAuth from "./RequireAuth";
import TwoFactorConfirmation from "../pages/TwoFactor/TwoFactorConfirmation";
import MyCalendar from "../pages/Booking/Calendar/Booking";
import SettingsDashboard from "../pages/Settings/SettingDashboard";
import LoginContainer from "../pages/User/Login/LoginContainer";
import TwoFactorAuthorization from "../pages/TwoFactor/TwoFactorAuthorization";
import NotFound from "../components/NotFound";
import ProfileDashboard from "../pages/User/Profile/ProfileDashboard";
import EventStatus from "../pages/Booking/Options/EventStatus";
import RestrictedAccess from "../pages/User/RestrictedAccess";
import ClientDashboard from "../pages/Client/Dashboard/ClientDashboard";
import ClientDetailsDashboard from "../pages/Client/Details/ClientDetailsDashboard";
import ClientForm from "../pages/Client/Form/ClientForm";
import ProcessDashboard from "../pages/Screening/Process/ProcessDashboard";
import SessionStartPage from "../pages/Screening/Process/Session/SessionStartPage";
import SessionOverview from "../pages/Screening/Process/Session/Dashboard/SessionOverview";
import SessionClientOnHold from "../pages/Screening/Process/Session/SessionClientOnHold";
import ScreeningDashboard from "../pages/Screening/ScreeningDashboard";
import SurveyInitializer from "../pages/Screening/Survey/Form/SurveyInitializer";
import SessionCodeStart from "../pages/Screening/Process/Session/SessionCodeStart";
import ClientPage from "../pages/Screening/ClientPage";
import StatsTable from "../pages/DataExport/StatsTable";
import DataExportDashboard from "../pages/DataExport/DataExportDashboard";
import AuditTrailsLogsTable from "../pages/DataExport/AuditTrailsLogsTable";
import NotificationLogsTable from "../pages/DataExport/NotificationLogsTable";
import StudyOverview from "../pages/Client/Dashboard/StudyOverview/StudyOverview";
import { AppRoles } from "../data/models/role";
import EventConfirmationStatus from "../pages/Booking/Calendar/EventConfirmationStatus";
import GroupDistributionScreen from "../pages/Screening/GroupDistribution/GroupDistributionScreen";

export const routes: RouteObject[] = [
    {
        path: '/',
        element: <App />,
        children: [
            {
                element: <RequireAuth />, children: [
                    { path: '/settings/:option', element: <SettingsDashboard /> },
                    { path: '/profile/:option', element: <ProfileDashboard /> },
                    { path: '/disabled', element: <RestrictedAccess /> },
                    { path: '/survey/:surveyId', element: <SurveyInitializer /> },
                    { path: '/screening/process', element: <ProcessDashboard /> },
                    { path: '/survey/response/:responseId', element: <SurveyInitializer /> },
                    { path: '/timeline/:timelineId/session/:sessionId', element: <SessionOverview /> },
                    { path: '/timeline/:timelineId/survey/:surveyId', element: <SurveyInitializer /> },

                ]
            },
            {
                element: <RequireAuth module="RESOURCE_PLANNING" permissions={["VIEW_ALL", "VIEW_OWN"]} requireAll={false} />, children: [
                    { path: '/resource-planning/view/:view', element: <MyCalendar /> },
                ]
            },
            {
                element: <RequireAuth module="SCREENING" permissions={["VIEW_ALL", "VIEW_OWN"]} requireAll={false} />, children: [
                    { path: '/screening', element: <ScreeningDashboard /> },
                ]
            },
            {
                element: <RequireAuth role={[
                    AppRoles.ADMIN,
                    AppRoles.TENANT_ADMIN,
                    AppRoles.RESEARCHER
                ]} />,
                children: [{
                    path: '/group-distribution',
                    element: <GroupDistributionScreen />,
                }],
            },
            {
                element: <RequireAuth role="CLIENT" redirect="/screening" />, children: [
                    { path: '/screening/my-sessions', element: <ClientPage /> },
                ]
            },
            {
                element: <RequireAuth module="CLIENTS" permissions={["VIEW_ALL", "VIEW_OWN"]} requireAll={false} />, children: [
                    { path: '/users', element: <ClientDashboard /> },
                    { path: '/users/:id', element: <ClientDetailsDashboard /> },
                    { path: '/users/:id/:view', element: <ClientDetailsDashboard /> },
                ]
            },
            {
                element: <RequireAuth module="CLIENTS" permissions={["EDIT_ALL", "EDIT_OWN"]} requireAll={false} />, children: [
                    { path: '/users/:id/update', element: <ClientForm /> },
                ]
            },
            {
                element: <RequireAuth module="CLIENTS" permissions={["VIEW_ALL", "VIEW_OWN"]} role="Field Coordinator" requireAll={false} />, children: [
                    { path: '/users/create', element: <ClientForm /> },
                ]
            },
            {
                element: <RequireAuth role={[AppRoles.ADMIN, AppRoles.TENANT_ADMIN]} />,
                children: [{
                    path: '/logs',
                    element: <AuditTrailsLogsTable />,
                }],
            },
            {
                element: <RequireAuth role={[AppRoles.ADMIN, AppRoles.FIELD_COORDINATOR]} />,
                children: [{
                    path: '/dashboard',
                    element: <StatsTable />,
                }],
            },
            {
                element: <RequireAuth role={[
                    AppRoles.ADMIN,
                    AppRoles.TENANT_ADMIN,
                    AppRoles.FIELD_COORDINATOR,
                    AppRoles.FIELD_COORDINATOR_LIGHT,
                    AppRoles.USZ_COORDINATOR
                ]} />,
                children: [{
                    path: '/notification-logs',
                    element: <NotificationLogsTable />,
                }],
            },
            {
                element: <RequireAuth role={[
                    AppRoles.ADMIN,
                    AppRoles.TENANT_ADMIN,
                    AppRoles.FIELD_COORDINATOR,
                    AppRoles.FIELD_COORDINATOR_LIGHT,
                    AppRoles.USZ_COORDINATOR
                ]} />,
                children: [
                    { path: 'coordination-overview', element: <StudyOverview /> },
                ]
            },

            { path: 'coordination-overview', element: <StudyOverview /> },
            { path: '/login', element: <LoginContainer /> },
            { path: '/two-factor', element: <TwoFactorConfirmation /> },
            { path: '/forgot-password', element: <ForgotPasswordForm /> },
            { path: '/reset-password/', element: <ResetPasswordForm /> },
            { path: '/two-factor-confirmation', element: <TwoFactorConfirmation /> },
            { path: '/two-factor-authorization', element: <TwoFactorAuthorization /> },
            { path: '/event-status', element: <EventStatus /> },
            { path: '/confirm-event', element: <EventConfirmationStatus /> },
            {
                element: <RequireAuth module="DOWNLOAD_NII" permissions={["VIEW_ALL", "VIEW_OWN"]} role="Researcher" requireAll={true} />, children: [
                    { path: '/data-export', element: <DataExportDashboard />, }
                ]
            },
            { path: '/session/:sessionId/code', element: <SessionCodeStart /> },
            { path: '/screening/session', element: <SessionStartPage /> },
            { path: '/timeline/:timelineId/survey/:surveyId/session/:sessionId', element: <SurveyInitializer /> },
            { path: '/screening/session/:sessionId/on-hold', element: <SessionClientOnHold /> },
            { path: 'not-found', element: <NotFound /> },
            { path: '*', element: <Navigate to="/not-found" /> },
        ]
    }
]

export const router = createBrowserRouter(routes);