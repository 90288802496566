import { useCallback, useEffect } from "react";
import { unstable_BlockerFunction, unstable_useBlocker } from "react-router-dom";
import { useStore } from "../../stores/store";

/**
 * Custom hook to block navigation when there are unsaved changes and handle browser tab close/refresh.
 * 
 * @param isDirty - A boolean value that determines whether navigation should be blocked;
 * 
 * @returns An object containing the blocker state and functions to proceed or reset the blocking behavior.
 * 
 * Usage:
 * This hook can be used in a component where navigation needs to be blocked due to unsaved changes.
 * isDirty `isDirty` is true, navigation is blocked, and a dialog is displayed to confirm if the user really wants to leave.
 * The hook also handles the `beforeunload` event for tab closure or refresh, prompting the user with a confirmation message.
 */
export function useBlockNavigation(isDirty: boolean) {
    const { modalStore, dialogStore } = useStore();
    const { closePmModal, openUnsavedChangesDialog } = modalStore;
    const { closeDialog } = dialogStore;
    const shouldBlock = useCallback<unstable_BlockerFunction>(
        ({ currentLocation, nextLocation }) => {
            return !!isDirty && currentLocation.pathname !== nextLocation.pathname;
        },
        [isDirty]
    );

    const blocker = unstable_useBlocker(shouldBlock);

    const handleConfirmNavigation = () => {
        if (!blocker || !blocker.proceed) return;
        closePmModal();
        closeDialog();
        blocker.proceed();
    };

    const handleCancelNavigation = () => {
        if (!blocker || !blocker.proceed) return;
        closePmModal();
        closeDialog();
        blocker.reset();
    };

    useEffect(() => {
        if (blocker.state === "blocked" && isDirty) {
            openUnsavedChangesDialog(handleConfirmNavigation, handleCancelNavigation);
        }

        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (isDirty) {
                event.returnValue = "You have unsaved changes!";
                return event.returnValue;
            }
        };

        if (isDirty) {
            window.addEventListener("beforeunload", handleBeforeUnload);
        }

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blocker.state, isDirty, modalStore]);

    return {
        blockerState: blocker.state,
        proceed: blocker.proceed,
        reset: blocker.reset,
    };
}
