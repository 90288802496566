import { Box, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import { useStore } from "../../../stores/store";
import GenericTable from "../../../components/GenericTable";
import { TableHeader } from "../../../_styles/table/TableHeader";
import { TableText } from "../../../_styles/table/TableText";
import { getSessionStatusChip } from "./Session/statusProps";
import StatusFilter from "./StatusFilters";
import { RoundButton } from "../../../_styles/StyledButtons";
import { SessionStatus } from "../../../data/models/session";
import { TableSearch } from "../../../_styles/table/TableSearch";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { getDateAndTimeFormatByPlatformLanguage } from "../../../utils/utils";

interface TableRow {
    id: string;
    status: SessionStatus;
    startTime: Date;
    clientFullName: string;
    assessorFullName: string;
}

function SessionTable() {
    const { t, i18n } = useTranslation();
    const { sessionStore, institutionStore } = useStore();
    const { getSessions, getAssessorSession } = sessionStore;
    const { selectedUserInstitution } = institutionStore;
    const [rows, setRows] = useState<TableRow[]>([]);
    const [statusFilters, setStatusFilters] = useState<SessionStatus[]>([]);
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const sessions = await getSessions();
            const data = sessions?.map((session) => {

                const sessionData = {
                    id: session.id,
                    status: session.status,
                    startTime: session.startTime,
                    clientFullName: session.clientFullName,
                    assessorFullName: session.assessorFullName,
                    timeline: session.timeline
                }

                if (sessionData.timeline === "Baseline" && sessionData.status === SessionStatus.AWAITING_EVENT_CREATION) {
                    sessionData.status = SessionStatus.AWAITING_BASELINE_EVENT_CREATION;
                }

                return sessionData;
            });
            if (data) {
                setRows(data);
            }
        };

        fetchData();
    }, [getSessions, selectedUserInstitution]);

    const onSessionNavigate = async (id: string) => {
        const session = await getAssessorSession(id);

        if (session) {
            navigate(
                `/timeline/${session.timelineId}/session/${session.sessionId}?clientId=${session.clientId}`
            );
        }
    };

    const getFilteredData = (): TableRow[] => {
        if (statusFilters.length === 0 || !rows) return rows;

        let filteredData: TableRow[] = [];

        if (statusFilters.includes(SessionStatus.ACTIVE)) {
            const activeSessions = rows.filter(
                (row) =>
                    row.status === SessionStatus.ACTIVE &&
                    new Date() > new Date(row.startTime)
            );

            filteredData.push(...activeSessions);
        }

        return rows.filter((row) => {
            if (row.status === SessionStatus.ACTIVE) {
                return filteredData.includes(row);
            }

            return statusFilters.includes(row.status) || filteredData.includes(row);
        });
    };

    const getData = () => {
        const filteredRows = getFilteredData();
        if (searchQuery !== "") {
            return filteredRows.filter(
                (row) =>
                    row.clientFullName
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase().trim()) ||
                    row.assessorFullName
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase().trim())
            );
        }
        return filteredRows;
    };

    return (
        <Box className="process-table">
            <GenericTable<TableRow>
                columns={[
                    {
                        header: <TableHeader>{t("SESSION_ATTENDANT")}</TableHeader>,
                        key: "attendant",
                        render: (session) => (
                            <TableText>{session.clientFullName}</TableText>
                        ),
                        sort: (a, b) =>
                            a.clientFullName
                                .toLowerCase()
                                .localeCompare(b.clientFullName.toLowerCase()),
                    },
                    {
                        header: <TableHeader>{t("SESSION_ASSESSOR")}</TableHeader>,
                        key: "assessor",
                        render: (session) => (
                            <TableText>{session.assessorFullName}</TableText>
                        ),
                        sort: (a, b) =>
                            a.assessorFullName
                                .toLowerCase()
                                .localeCompare(b.assessorFullName.toLowerCase()),
                    },
                    {
                        header: <TableHeader>{t("CLIENT_PROCESS_STATUS")}</TableHeader>,
                        key: "status",
                        render: (session) => <Box>{getSessionStatusChip(session.status, t)}</Box>,
                        sort: (a, b) =>
                            a.status
                                .toLowerCase()
                                .localeCompare(b.status.toLowerCase())
                    },
                    {
                        header: <TableHeader>{t("EVENT_START_TIME")}</TableHeader>,
                        key: "startTime",
                        render: (session) => (
                            <TableText>
                                {getDateAndTimeFormatByPlatformLanguage(new Date(session.startTime), i18n.language)}
                            </TableText>
                        ),
                        sort: (a, b) => {
                            if (a.startTime) {
                                if (b.startTime) {
                                    return a.startTime === b.startTime
                                        ? 0
                                        : a.startTime > b.startTime
                                            ? 1
                                            : -1;
                                }
                                return 1;
                            }
                            return 0;
                        },
                    },
                    {
                        header: <></>,
                        key: "actions",
                        render: (session) => (
                            <RoundButton
                                variant="text"
                                disableFocusRipple
                                disableTouchRipple
                                sx={{
                                    p: 0,
                                    color: "#0077b6",
                                    textTransform: "none",
                                    "&:hover": { textDecoration: "underline" },
                                    fontWeight: 400,
                                }}
                                onClick={() => onSessionNavigate(session.id)}
                            >
                                {t("GENERAL_VIEW")}
                            </RoundButton>
                        ),
                    },
                ]}
                rows={getData() || []}
                props={{
                    containerProps: { className: "survey-table-container" },
                    tableContainerProps: {
                        className: "survey-table-container-data",
                        style: { maxHeight: 400 },
                    },
                    tableHeadProps: { className: "survey-table-header" },
                    rowProps: { className: "survey-row" },
                }}
                rowKey="id"
                usePagination
                toolbar={
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            mb: 2,
                        }}
                    >
                        <Typography fontWeight={500} mb={3}>
                            {t("SESSIONS_LABEL")}
                        </Typography>
                        <Box display={"flex"}>
                            <TableSearch
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder={t("SESSION_SEARCH")}
                                title="Search by attendant or assessor"
                                sx={{
                                    mr: 2
                                }}
                                InputProps={{
                                    endAdornment: <SearchIcon sx={{ color: "#ddd" }} />,
                                }}
                            />
                            <StatusFilter
                                statusFilters={statusFilters}
                                setStatusFilters={setStatusFilters}
                            />
                        </Box>
                    </Box>
                }
            />
        </Box>
    );
}

export default observer(SessionTable);
