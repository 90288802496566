import { useState } from "react";
import { ProcessDetails } from "../../../../data/models/process";
import { useStore } from "../../../../stores/store";
import { useTranslation } from "react-i18next";
import agent from "../../../../utils/agent";
import { toast } from "react-toastify";
import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

export default function GroupDistribution({ process, fetchProcess }: { process: ProcessDetails, fetchProcess: () => Promise<void> }) {
    const [processGroupId, setProcessGroupId] = useState<string | undefined | null>(process.selectedGroupDistributionId);
    const [selectedGroup, setSelectedGroup] = useState<string>(process.selectedGroupDistributionId ?? 'automatic');
    const { institutionStore: { selectedUserInstitution } } = useStore();
    const { t } = useTranslation();

    const onChange = (groupId: string) => {
        setSelectedGroup(groupId);
    }

    const save = async () => {
        const body = {
            groupId: selectedGroup === 'automatic' ? null : selectedGroup
        }
        try {
            await agent.Institutions.setGroupDistribution(selectedUserInstitution!.institutionId, body);
            setProcessGroupId(body.groupId);
            toast.success("Subsample updated");
            await fetchProcess();
        }
        catch (error) {
            toast.error("Subsample switch failed");
        }
    }

    const isDisabled = () => {
        if (processGroupId === null && selectedGroup === 'automatic') return true;

        return selectedGroup === processGroupId;
    }

    return (
        <Box height={350}>
            <h3>{t("GROUP_DISTRIBUTION")}</h3>
            {process.groupDistribution.filter(g => g.count > 0).map(g => {
                return <div>
                    {g.group} / {g.subsample} - {g.count} sessions
                </div>
            })}

            <div style={{ margin: '20px 0' }}>
                <Select value={selectedGroup} sx={{ fontSize: "13px" }}
                    onChange={(event) => onChange(event.target.value)}
                    SelectDisplayProps={{
                        style: {
                            width: "140px",
                            padding: "8px 10px",
                            backgroundColor: "white",
                        },
                    }}
                    MenuProps={{
                        style: {
                            maxHeight: "300px",
                            maxWidth: "300px",
                        },
                    }}>
                    {[...process.groupDistribution, ...[{ id: 'automatic', subsample: 'Automatic', group: null }]].map(x =>
                        <MenuItem key={x.id} value={x.id} sx={{ fontSize: "13px" }}>{x.subsample}</MenuItem>
                    )}
                </Select>
                <Button
                    disabled={isDisabled()}
                    onClick={save}
                    variant="contained"
                >
                    {t("GENERAL_SAVE")}
                </Button>
            </div>
            <InfoIcon />
            {selectedGroup === 'automatic' ?
                <Typography fontSize={12}>
                    {t("AUTOMATIC_DESCRIPTION")}
                </Typography>
                :
                <Typography fontSize={12}>
                    {t("SUBSAMPLE_DESCRIPTION")}
                </Typography>
            }
        </Box>
    )
}