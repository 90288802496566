import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
} from "@mui/material";
import { residencePermitOptions } from "../Settings/User/residencePermitOptions";
import { genderOptions } from "../../data/static/user";
import "./styles.css";
import { DemographicStats } from "../../data/models/stats";
import { capitalizeFirstLetter } from "../../utils/utils";
import { useTranslation } from "react-i18next";

interface DemographicTableProps {
    data: DemographicStats[];
    title?: string;
}

function DemographicTable({ data, title }: DemographicTableProps) {
    const { t } = useTranslation();
    const genders = genderOptions;
    const residenceStatuses = [
        ...residencePermitOptions,
        { id: "-1", name: "none" },
    ].map((status) => status.name);
    const cities = Array.from(
        new Set(
            data
                .filter((result) => result.type === "city")
                .map((result) => result.location)
        )
    );
    const accomodations = Array.from(
        new Set(
            data
                .filter((result) => result.type === "accomodation")
                .map((result) => result.location)
        )
    );
    const totalColumns = genderOptions.length * residenceStatuses.length;

    if (data.length === 0)
        return (
            <>
                {title && (
                    <Typography component={"h2"} variant="h6">
                        {title}
                    </Typography>
                )}
                <Box ml={1} fontSize={"11px"}>
                    {t("GENERAL_NO_RESULT")}
                </Box>
            </>
        );

    return (
        <TableContainer
            component={Paper}
            sx={{
                bgcolor: "#fff",
                border: "1px solid rgba(224, 224, 224, 1)",
                mb: 2,
            }}
        >
            {title && (
                <Box sx={{ p: 1 }}>
                    <Typography component={"h2"} variant="h6">
                        {title}
                    </Typography>
                </Box>
            )}
            <Table>
                <TableHead>
                    <TableRow>
                        {genders.map((gender, index) => (
                            <TableCell
                                key={gender.value}
                                colSpan={
                                    index === 0
                                        ? residenceStatuses.length + 1
                                        : residenceStatuses.length
                                }
                                sx={{ textAlign: "center" }}
                            >
                                {gender.label}
                            </TableCell>
                        ))}
                    </TableRow>
                    <TableRow>
                        <TableCell className="sticky-cell" />
                        {genders.map((gender) =>
                            residenceStatuses.map((status) => (
                                <TableCell
                                    key={`${gender.value}-${status}`}
                                    sx={{
                                        textAlign: "center",
                                        fontStyle: status ? "normal" : "italic",
                                        fontWeight: status ? 600 : 400,
                                        maxWidth: "100px",
                                        maxHeight: "100px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                    }}
                                    title={`${gender.value} ${status}`}
                                >
                                    {status ?? "null"}
                                </TableCell>
                            ))
                        )}
                        <TableCell sx={{ textAlign: "center" }}>Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell className="sticky-cell">{t("GENERAL_CITY")}</TableCell>
                        {Array.from({ length: totalColumns + 1 }).map((_, index) => (
                            <TableCell
                                key={index}
                                sx={{ textAlign: "center", bgcolor: "#ffc10726" }}
                            >
                                -
                            </TableCell>
                        ))}
                    </TableRow>
                    {cities.map((city, index) => (
                        <TableRow key={`${city}-${index}`}>
                            <TableCell
                                className="sticky-cell"
                                sx={{ fontStyle: city ? "normal" : "italic" }}
                            >
                                {city ?? t("CLIENT_HELPER_UNSP")}
                            </TableCell>
                            {genders.map((gender) =>
                                residenceStatuses.map((status) => {
                                    const filteredResult = data.find((r) => {
                                        const dd =
                                            r.location === city &&
                                            r.gender === gender.value &&
                                            r.residenceStatus === status &&
                                            r.type === "city";
                                        return dd;
                                    });
                                    return (
                                        <TableCell
                                            key={`${city}-${gender.value}-${status}`}
                                            sx={{
                                                textAlign: "center",
                                                pl: 1,
                                                bgcolor:
                                                    filteredResult && filteredResult.total !== 0
                                                        ? "#03b2ff17"
                                                        : "#fff",
                                            }}
                                        >
                                            {filteredResult?.total || 0}
                                        </TableCell>
                                    );
                                })
                            )}
                            <TableCell
                                sx={{
                                    textAlign: "center",
                                    fontWeight: 600,
                                    bgcolor: "#ffc10726",
                                }}
                            >
                                {data
                                    .filter((result) => {
                                        return result.location === city && result.type === "city";
                                    })
                                    .reduce((acc, result) => {
                                        return acc + result.total;
                                    }, 0)}
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell className="sticky-cell">{t("CLIENT_ACCOMODATION")}</TableCell>
                        {Array.from({ length: totalColumns + 1 }).map((_, index) => (
                            <TableCell
                                key={index}
                                sx={{ textAlign: "center", bgcolor: "#ffc10726" }}
                            >
                                -
                            </TableCell>
                        ))}
                    </TableRow>
                    {accomodations.map((acc) => (
                        <TableRow key={acc}>
                            <TableCell
                                className="sticky-cell"
                                sx={{ fontStyle: acc ? "normal" : "italic" }}
                            >
                                {acc ? capitalizeFirstLetter(acc) : t("CLIENT_HELPER_UNSP")}
                            </TableCell>
                            {genders.map((gender) =>
                                residenceStatuses.map((status) => {
                                    const filteredResult = data.find(
                                        (r) =>
                                            r.location === acc &&
                                            r.gender === gender.value &&
                                            r.residenceStatus === status &&
                                            r.type === "accomodation"
                                    );
                                    return (
                                        <TableCell
                                            key={`${acc}-${gender.value}-${status}`}
                                            sx={{
                                                textAlign: "center",
                                                bgcolor:
                                                    filteredResult && filteredResult.total !== 0
                                                        ? "#03b2ff17"
                                                        : "#fff",
                                            }}
                                        >
                                            {filteredResult?.total || 0}
                                        </TableCell>
                                    );
                                })
                            )}
                            <TableCell
                                sx={{
                                    textAlign: "center",
                                    fontWeight: 600,
                                    bgcolor: "#ffc10726",
                                }}
                            >
                                {data
                                    .filter(
                                        (result) =>
                                            result.location === acc && result.type === "accomodation"
                                    )
                                    .reduce((acc, result) => {
                                        return acc + result.total;
                                    }, 0)}
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell className="sticky-cell">{t("CARD_TOTAL")}</TableCell>
                        {genders.map((gender) =>
                            residenceStatuses.map((status) => (
                                <TableCell
                                    key={`${gender.value}-${status}`}
                                    sx={{
                                        textAlign: "center",
                                        fontWeight: status ? 600 : 400,
                                        bgcolor: "#ffc10726",
                                    }}
                                    title={`${gender.value} ${status} total`}
                                >
                                    {data
                                        .filter(res => res.gender === gender.value && res.residenceStatus === status)
                                        .map(res => res.userIds)
                                        .flat()
                                        .filter((value, index, self) => self.indexOf(value) === index)
                                        .length}
                                </TableCell>
                            ))
                        )}
                        <TableCell
                            sx={{
                                textAlign: "center",
                                fontWeight: 600,
                                bgcolor: "#ffc10726",
                            }}
                        >
                            {data
                                .map(res => res.userIds)
                                .flat()
                                .filter((value, index, self) => self.indexOf(value) === index)
                                .length}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="sticky-cell">{t("STAT_TOTAL_GENDER")}</TableCell>
                        {genders.map((gender) => (
                            <TableCell
                                colSpan={residenceStatuses.length}
                                key={`${gender.value}-total`}
                                sx={{
                                    textAlign: "center",
                                    fontWeight: 600,
                                    bgcolor: "#ffc10726",
                                }}
                                title={`${gender.value} gender total`}
                            >
                                {data
                                    .filter(res => res.gender === gender.value)
                                    .map(res => res.userIds)
                                    .flat()
                                    .filter((value, index, self) => self.indexOf(value) === index)
                                    .length}
                            </TableCell>
                        ))}
                        <TableCell
                            sx={{
                                textAlign: "center",
                                fontWeight: 600,
                                bgcolor: "#ffc10726",
                            }}
                        >
                            {data
                                .map(res => res.userIds)
                                .flat()
                                .filter((value, index, self) => self.indexOf(value) === index)
                                .length}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default DemographicTable;
