import { t } from "i18next";

export interface WeekDay {
    value: string;
    label: string;
}

export interface RecurrenceWeekDay {
    value: number;
    label: string;
    tag: string;
    translatedName?: string;
}

export const weekDays: WeekDay[] = [
    { value: 'monday', label: 'MO' },
    { value: 'tuesday', label: 'TU' },
    { value: 'wednesday', label: 'WE' },
    { value: 'thursday', label: 'TH' },
    { value: 'friday', label: 'FR' },
    { value: 'saturday', label: 'SA' },
    { value: 'sunday', label: 'SU' },
]

export const recurrenceWeekDays: RecurrenceWeekDay[] = [
    { value: 1, label: 'Monday', tag: 'MO' },
    { value: 2, label: 'Tuesday', tag: 'TU' },
    { value: 3, label: 'Wednesday', tag: 'WE' },
    { value: 4, label: 'Thursday', tag: 'TH' },
    { value: 5, label: 'Friday', tag: 'FR' },
    { value: 6, label: 'Saturday', tag: 'SA' },
    { value: 0, label: 'Sunday', tag: 'SU' },
]