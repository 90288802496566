import { observer } from "mobx-react-lite";
import { useStore } from "../../../stores/store";
import { ProcessDetails } from "../../../data/models/process";
import { useEffect, useState } from "react";
import LoadingComponent from "../../../components/LoadingComponent";
import GroupDistribution from "../Process/SubsampleSwitch/SwitchSubsampleForSpiritStudy";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

function GroupDistributionScreen() {
    const {
        processStore,
        institutionStore
    } = useStore();
    const { getProcessList, loading } = processStore;
    const { selectedUserInstitution } = institutionStore;
    const [processList, setProcessList] = useState<ProcessDetails[]>([]);
    const [selectedProcess, setSelectedProcess] = useState<ProcessDetails | null>(null);
    const navigate = useNavigate();

    const fetchProcessData = async () => {
        if (selectedUserInstitution) {
            const processes = await getProcessList(selectedUserInstitution.institutionId);
            if (processes.length > 0) {
                setProcessList(processes);
                setSelectedProcess(processes[0]);
            }
        }
    };

    useEffect(() => {
        fetchProcessData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUserInstitution]);

    if (loading) return (
        <LoadingComponent gridProps={{
            style: {
                height: "50vh"
            }
        }} />
    );

    if (selectedUserInstitution?.study === "BRIGHT") {
        navigate('/profile/account');
    }

    return (
        <Container maxWidth="xl" className="screening-container">
            {selectedProcess && <GroupDistribution process={selectedProcess!} fetchProcess={fetchProcessData} />}
        </Container>
    )
}

export default observer(GroupDistributionScreen);