import { styled } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export const CloseIcon = styled(CloseRoundedIcon)(({ theme }) => ({
    color: theme.palette.common.white,
    backgroundColor: "#e63946",
    borderRadius: "50%",
    stroke: theme.palette.common.white,
    strokeWidth: "3px",
    fontSize: "10px",
    padding: "5px",
}));
