import React from "react";
import { CheckIcon } from "../../../_styles/icons/CheckIcon";
import { CloseIcon } from "../../../_styles/icons/CloseIcon";
import { PendingIcon } from "../../../_styles/icons/PendingIcon";
import { StatusChip } from "../../../components/form/StatusChip";
import { TFunction } from "i18next";
import { AssessorEventStatus } from "../../../data/models/event";
import { SxProps } from "@mui/material";

interface EventStatusDisplayProps {
    eventStatus: string;
    t: TFunction<"translation", undefined>;
    sx?: SxProps;
}

const getEventStatus = (status?: string | null): AssessorEventStatus | null => {
    if (status === AssessorEventStatus.CONFIRMED) return AssessorEventStatus.CONFIRMED;
    if (status === AssessorEventStatus.DECLINED) return AssessorEventStatus.DECLINED;
    if (status === AssessorEventStatus.PENDING) return AssessorEventStatus.PENDING;
    return null;
};

interface StatusProps {
    icon: JSX.Element;
    color: string;
}

const getStatusProps = (status?: string | null): StatusProps | null => {
    if (status === AssessorEventStatus.CONFIRMED) {
        return { icon: <CheckIcon />, color: "#90EE90" };
    }
    if (status === AssessorEventStatus.DECLINED) {
        return { icon: <CloseIcon />, color: "#ffbaba" };
    }
    if (status === AssessorEventStatus.PENDING) {
        return { icon: <PendingIcon />, color: "#fcfc7e" };
    }
    return null;
};

const EventStatusDisplay: React.FC<EventStatusDisplayProps> = ({ eventStatus, t, sx }) => {
    const status = getEventStatus(eventStatus);

    if (!status) return null;

    const statusProps = getStatusProps(eventStatus);

    if (!statusProps) return null;

    return (
        <StatusChip
            status={status}
            attendeeStatus=""
            t={t}
            dueDate={new Date()}
            getStatusProps={() => statusProps}
            sx={sx}
        />
    );
};

export default EventStatusDisplay;
