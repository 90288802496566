import { Box, Button, CircularProgress, Grid } from "@mui/material";

import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

import GenericTable from "../../../components/GenericTable";
import { TableHeader } from "../../../_styles/table/TableHeader";
import { TableText } from "../../../_styles/table/TableText";
import { getSessionStatusChip } from "../../Screening/Process/Session/statusProps";
import { ClientDetailSessionsDto } from "../../../data/models/client";
import ClientSessionActionDialog from "./ClientSessionActionDialog";
import { useStore } from "../../../stores/store";
import { SessionAction, SessionDeactivateRequest, SessionDialogData, SessionEmergencyRequest, SessionStatus } from "../../../data/models/session";
import { hasRoles, hasRolesv2 } from "../../../utils/permissionEvaluator";
import { followUpOptions } from "../../../data/static/followupOptions";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { AppRoles } from "../../../data/models/role";
import agent from "../../../utils/agent";

interface ClientDetailSessionTableProps {
    sessions: ClientDetailSessionsDto[];
    updateSession: () => {};
}

function ClientDetailSessionTable({ sessions, updateSession }: ClientDetailSessionTableProps) {
    const { t } = useTranslation();
    const { sessionStore, clientStore, loadingStore,
        institutionStore: { selectedUserInstitution } } = useStore();
    const { getSessionsOnClientDetail, fetchTimeline } = clientStore;
    const { id } = useParams();
    const { isLoading } = loadingStore;
    const { dialogStore: { openDialog, closeDialog } } = useStore();
    const [isActionTriggered, setIsActionTriggered] = useState(false);

    const sessionAction = async (selectedSession: ClientDetailSessionsDto, action: SessionAction) => {
        if (action === SessionAction.RESUME) {
            const resumeEmergencyObject: SessionEmergencyRequest = {
                emergencyHold: false,
            }

            try {
                await sessionStore.sessionEmergency(selectedSession.id, resumeEmergencyObject);
            } catch (error) {
                throw error;
            }

            updateSession();
            return;
        }

        setIsActionTriggered(true);
        openDialog(
            t(action === SessionAction.DEACTIVATE ? "DIALOG_CLIENT_SESSION_DEACTIVATE" : "DIALOG_CLIENT_SESSION_EMERGENCY"),
            t(action === SessionAction.DEACTIVATE ? "DIALOG_CLIENT_SESSION_DEACTIVATE_DESCRIPTION" : "DIALOG_CLIENT_SESSION_EMERGENCY_DESCRIPTION"),
            handleConfirm,
            handleClose,
            {
                text: t("GENERAL_CONFIRM"),
                color: "warning",
            },
            selectedSession,
            action
        );
    }

    const restoreFromGroupC = async (sessionId: string) => {
        openDialog(
            t("DIALOG_CLIENT_SESSION_RESTORE"),
            t("DIALOG_CLIENT_SESSION_RESTORE_DESCRIPTION"),
            async () => {
                await agent.SessionOperations.restoreSession(sessionId);
                closeDialog();
                updateSession();
            },
            closeDialog,
            {
                text: t("CONTINUE"),
                color: "warning",
            },
        )
    }

    const handleConfirm = async (data: SessionDialogData) => {
        if (data.action && data.action === SessionAction.DEACTIVATE) {
            try {
                await sessionStore.deactivateSession(data.sessionId, data.sessionData as SessionDeactivateRequest)
            } catch (error) {
                throw error;
            }
        }

        if (data.action && data.action === SessionAction.EMERGENCY) {
            try {
                await sessionStore.sessionEmergency(data.sessionId, data.sessionData as SessionEmergencyRequest)
            } catch (error) {
                throw error;
            }
        }

        updateSession();
        closeDialog();
    }

    const onSessionRowClick = async (sessionId: string) => {
        if (selectedUserInstitution?.institutionId && id) {
            await fetchTimeline(id, selectedUserInstitution.institutionId, sessionId);
        }
    };

    const handleClose = () => {
        setIsActionTriggered(false);
        closeDialog();
    }

    return (
        <Grid container columnSpacing={1} >
            {isActionTriggered && <ClientSessionActionDialog />}
            <Grid item xs={12}>
                <GenericTable<ClientDetailSessionsDto>
                    columns={[
                        {
                            header: (
                                <TableHeader fontSize={"13px !important"}>{t("SESSION_ASSESSOR")}</TableHeader>
                            ),
                            key: "assessorName",
                            render: (session) => <TableText>{session.assessorFullName}</TableText>,
                            className: "process-cell",
                        },
                        {
                            header: (
                                <TableHeader fontSize={"13px !important"}>{t("CLIENT_PROCESS_STATUS")}</TableHeader>
                            ),
                            key: "status",
                            render: (session) => (
                                <>
                                    <Box>{getSessionStatusChip(session.status, t)}</Box>
                                </>
                            ),
                            className: "process-cell",
                        },
                        {
                            header: (
                                <TableHeader fontSize={"13px !important"}>
                                    {t("SESSION_TIMELINE")}
                                </TableHeader>
                            ),
                            key: "timeline",
                            render: (session) => (
                                <TableText>
                                    {session.timeline}
                                </TableText>
                            ),
                            className: "process-cell",
                        },
                        {
                            header: (
                                <TableHeader fontSize={"13px !important"}>
                                    {t("TRIAGE_CONSENT")}
                                </TableHeader>
                            ),
                            key: "triageAccepted",
                            render: (session) => (
                                <TableText>
                                    {session.isTriageConsentCompleted
                                        ? session.triageAccepted ? t("GENERAL_ACCEPTED") : t("GENERAL_REJECTED")
                                        : t("TRIAGE_CONSENT_NOT_FILLED_LABEL")}
                                </TableText>
                            ),
                            className: "process-cell",
                        },
                        {
                            header: (
                                <TableHeader fontSize={"13px !important"}>
                                    {t("SESSION_FOLLOWUP")}
                                </TableHeader>
                            ),
                            key: "followupType",
                            render: (session) => (
                                <TableText>
                                    {followUpOptions.find(f => f.value === session.followupType)?.label}
                                </TableText>
                            ),
                            className: "process-cell",
                        },
                        {
                            header: (<></>),
                            key: "restoreFromGroupCAction",
                            render: (session) => (
                                <>
                                    {hasRolesv2([AppRoles.ADMIN, AppRoles.FIELD_COORDINATOR], selectedUserInstitution?.roles ?? []) &&
                                        session.canBeRestoredFromGroupC &&
                                        <TableText color={'orange'} >
                                            <Button
                                                onClick={(e) => {
                                                    restoreFromGroupC(session.id)
                                                }}
                                                size="small" variant="contained">{t("CONTINUE_ANYWAY")}</Button>
                                        </TableText>
                                    }
                                </>
                            ),
                            className: "process-cell",
                        },
                        {
                            header: (<></>),
                            key: "emergencyAction",
                            render: (session) => (
                                <>
                                    {!hasRoles(
                                        ["Contact Manager"],
                                        selectedUserInstitution?.roles ?? [],
                                        false
                                    ) && session.status === SessionStatus.EMERGENCY_HOLD ? (isLoading(getSessionsOnClientDetail) ? (
                                        <CircularProgress color="secondary" size={25} />
                                    ) : (
                                        <TableText
                                            color={'primary'}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                sessionAction(session, SessionAction.RESUME);
                                            }}
                                            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                                        >
                                            {t("CLIENT_RESUME")}
                                        </TableText>
                                    )) : ''}
                                    {!hasRoles(
                                        ["Contact Manager"],
                                        selectedUserInstitution?.roles ?? [],
                                        false
                                    ) &&
                                        session.status !== SessionStatus.EMERGENCY_HOLD &&
                                        session.status !== SessionStatus.DEACTIVATED &&
                                        session.status !== SessionStatus.ABORT &&
                                        session.status !== SessionStatus.EXCLUDED &&
                                        session.status !== SessionStatus.BLONLY &&
                                        session.status !== SessionStatus.EXCLUDED_SUICIDALITY &&
                                        session.status !== SessionStatus.COMPLETED &&
                                        session.status !== SessionStatus.CLIENT_NO_SHOW_UP &&
                                        <TableText color={'orange'} onClick={(e) => {
                                            e.stopPropagation();
                                            sessionAction(session, SessionAction.EMERGENCY)
                                        }} sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                            {t("CLIENT_EMERGENCY")}
                                        </TableText>
                                    }
                                </>
                            ),
                            className: "process-cell",
                        },
                        {
                            header: (<></>),
                            key: "deactivateAction",
                            render: (session) => (
                                <>
                                    {!hasRoles(
                                        ["Contact Manager"],
                                        selectedUserInstitution?.roles ?? [],
                                        false
                                    ) && (
                                            <TableText color={'red'} onClick={(e) => {
                                                e.stopPropagation();
                                                sessionAction(session, SessionAction.DEACTIVATE)
                                            }}
                                                sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                                {
                                                    session.status !== SessionStatus.DEACTIVATED &&
                                                        session.status !== SessionStatus.ABORT &&
                                                        session.status !== SessionStatus.EXCLUDED &&
                                                        session.status !== SessionStatus.BLONLY &&
                                                        session.status !== SessionStatus.EXCLUDED_SUICIDALITY &&
                                                        session.status !== SessionStatus.COMPLETED &&
                                                        session.status !== SessionStatus.CLIENT_NO_SHOW_UP
                                                        ? t("CLIENT_DEACTIVATE")
                                                        : ''
                                                }
                                            </TableText>
                                        )
                                    }
                                </>
                            ),
                            className: "process-cell",
                        },
                    ]}
                    onRowClick={(row) => {
                        onSessionRowClick(row.id);
                    }}
                    isRowClickable={true}
                    rows={sessions || []}
                    props={{
                        tableContainerProps: { className: "survey-table-container-data" },
                        tableHeadProps: { className: "survey-table-header" },
                        rowProps: { className: "survey-row" },
                    }} rowKey="id"
                />
            </Grid>
        </Grid >
    );
}

export default observer(ClientDetailSessionTable);