import { Box, CircularProgress, Container, Divider, IconButton, Typography } from "@mui/material";
import EditOffRoundedIcon from "@mui/icons-material/EditOffRounded";

import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import "../styles.css";
import { QuestionType, Survey, SurveyTranslateField } from "../../../../data/models/survey";
import QuestionContainer from "../SurveyFields/QuestionContainer";
import { FormInputText } from "../../../../components/form/FormInputText";
import { SurveyContext } from "../Form/SurveyInitializer";
import { RoundButton } from "../../../../_styles/StyledButtons";
import { SurveyCreatorField } from "../SurveyFields/SurveyField";
import SurveyLanguageSelect from "../Form/SurveyLanguageSelect";
import { useStore } from "../../../../stores/store";
import { getTranslations } from "../../../../utils/helpers/surveyTranslation";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useBlockNavigation } from "../../../../components/hooks/useBlockNavigation";

type Mode = "preview" | "form";
type View = "assessor" | "client" | "user" | "admin";
type Direction = "rtl" | "ltr";

interface SurveyCreatorProps {
    survey: Survey;
    mode?: Mode;
    view?: View;
    onLanguageChange: (languageCode: string) => void;
    reload: (lang: string) => void;
    direction?: Direction;
}

function SurveyCreator({
    survey,
    mode = "form",
    view,
    onLanguageChange,
    reload,
    direction = "ltr",
}: SurveyCreatorProps) {
    const { surveyStore, loadingStore } = useStore();
    const { getSurvey, translate } = surveyStore;
    const { isLoading } = loadingStore;

    const { handleSubmit, control, formState: { isDirty } } = useForm();
    useBlockNavigation(isDirty);
    const { editMode, setEditMode, setBaseSurvey } = useContext(SurveyContext);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const lang = params.get("lang");

    useEffect(() => {
        const fetchData = () => {
            if (survey) {
                getSurvey(survey.id, 'en').then(survey => {
                    setBaseSurvey(survey);
                });
            }
        }

        fetchData();
    }, [getSurvey, setBaseSurvey, survey]);

    const preview = true;
    const lastPageIndex = survey?.pages.length;

    const [page, setPage] = useState(1);

    const showNextPage = () => {
        if (lastPageIndex && page > lastPageIndex) return;
        setPage((page) => page + 1);
    };

    const showPreviousPage = () => {
        if (page === 1) return;
        setPage((page) => page - 1);
    };

    const onSubmit = async (data: any) => {
        if (page !== lastPageIndex) {
            showNextPage();
            return;
        }
        const translations: SurveyTranslateField[] = getTranslations(survey, data, lang ?? "en");

        await translate(survey.id, translations);
        reload(lang ?? "en");
        // fetch base survey only if english translations were updated
        if (lang !== "en") {
            const enSurvey = await getSurvey(survey.id);
            setBaseSurvey(enSurvey);
        }
        setEditMode(false);
    }

    return (
        <Box className="creator-container" dir={direction}>
            <Container maxWidth="md" sx={{ pb: preview ? "1rem" : 0 }}>
                <Box component="form" width="100%" onSubmit={handleSubmit(onSubmit)}>
                    <QuestionContainer>
                        <Box className="survey-header-container">
                            <Box width={editMode ? "80%" : "auto"}>
                                {(preview ||
                                    ["assessor", "user", "admin"].includes(view ?? "")) &&
                                    <Typography fontSize={"32px"}>
                                        {survey?.name ?? "Untitled"}
                                    </Typography>
                                }
                                {editMode ? (
                                    <FormInputText
                                        label={`Page ${page} description`}
                                        name={`${survey?.pages[page - 1].id}.description`}
                                        control={control}
                                        rows={3}
                                        initValue={survey?.pages[page - 1].description ?? ""}
                                    />
                                ) : (
                                    <Typography fontSize={"14px"} className="survey-description">
                                        {survey?.pages[page - 1].description ?? ""}
                                    </Typography>
                                )}
                            </Box>
                            <Box display={"flex"}>
                                <IconButton
                                    sx={{ height: "36px", width: "36px" }}
                                    onClick={() => {
                                        setEditMode(!editMode);
                                    }}
                                >
                                    <EditOffRoundedIcon sx={{ fontSize: "18px" }} />
                                </IconButton>
                                <SurveyLanguageSelect onSelect={onLanguageChange} />
                            </Box>
                        </Box>
                        {(survey?.pages[page - 1].description || survey.description) && (
                            <Divider sx={{ margin: "1rem 0" }} />
                        )}
                        <Box className="survey-header-info">
                            {preview && (
                                <Typography
                                    fontSize={"12px"}
                                    sx={{ color: "#808080", ml: 1 }}
                                    className="survey-description"
                                >
                                    (Edit mode)
                                </Typography>
                            )}
                        </Box>
                    </QuestionContainer>
                    {survey?.pages[page - 1].questions.map((question) =>
                        question.subQuestions?.length! > 0 &&
                            question.questionType !== QuestionType.MATRIX ? (
                            <Box key={question.id}>
                                <SurveyCreatorField
                                    id={question.id}
                                    title={question.title}
                                    control={control}
                                    visible={true}
                                    disabled={view === "admin"}
                                    options={question.options}
                                    subQuestions={[]}
                                    preview={preview}
                                    required={question.required}
                                    type={question.questionType}
                                />
                                {question.subQuestions?.map((subQuestion) => (
                                    <SurveyCreatorField
                                        key={subQuestion.id}
                                        id={subQuestion.id}
                                        title={subQuestion.title}
                                        control={control}
                                        visible={true}
                                        disabled={view === "admin"}
                                        options={subQuestion.options}
                                        subQuestions={[]}
                                        preview={preview}
                                        required={subQuestion.required}
                                        type={subQuestion.questionType}
                                    />
                                ))}
                            </Box>
                        ) : (
                            <SurveyCreatorField
                                key={question.id}
                                id={question.id}
                                title={question.title}
                                control={control}
                                visible={true}
                                disabled={view === "admin"}
                                options={question.options}
                                preview={preview}
                                subQuestions={question.subQuestions}
                                required={question.required}
                                type={question.questionType}
                            />
                        )
                    )}
                    {page > 1 && (
                        <RoundButton
                            variant="outlined"
                            type="button"
                            sx={{ mb: "2rem", mr: "1rem" }}
                            onClick={() => showPreviousPage()}
                        >
                            Back
                        </RoundButton>
                    )}
                    {lastPageIndex === page ? (
                        <RoundButton
                            disabled={isLoading(translate)}
                            variant="contained"
                            type="submit"
                            sx={{ mb: "2rem" }}
                        >
                            {isLoading(translate) ? (
                                <CircularProgress size={25} sx={{ color: "#fff" }} />
                            ) : (
                                "Save"
                            )}
                        </RoundButton>
                    ) : (
                        <RoundButton variant="contained" type="submit" sx={{ mb: "2rem" }}>
                            Next
                        </RoundButton>
                    )}
                </Box>
            </Container>
        </Box>
    );
}

export default observer(SurveyCreator);
