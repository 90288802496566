import { styled } from "@mui/material";
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';

export const PendingIcon = styled(MoreHorizSharpIcon)(({ theme }) => ({
    fill: "white",
    backgroundColor: "#d9d905",
    borderRadius: "50%",
    fontSize: "15px",
    padding: "5px",
}));
