import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Box, Button, Menu, MenuItem, Link } from "@mui/material";
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import CastConnectedRoundedIcon from "@mui/icons-material/CastConnectedRounded";
import CastOutlinedIcon from "@mui/icons-material/CastOutlined";
import { useTranslation } from 'react-i18next';
import { AppRoles } from '../../data/models/role';
import { hasRolesv2 } from '../../utils/permissionEvaluator';

interface Props {
    userRoles: string[];
    navLinkStyle: object;
    iconStyle: object;
    activeIcon: object;
    selectedRipple?: object;
    onClick?: () => void;
    study: string
}

const ScreeningSelector: React.FC<Props> = ({
    userRoles,
    navLinkStyle,
    iconStyle,
    activeIcon,
    selectedRipple,
    onClick,
    study
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const isInPath = (paths: string[]) => paths.some(path => location.pathname.startsWith(path));

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isActive = isInPath(['/screening', '/group-distribution']);

    return (
        <>
            <Box display={"flex"} position={"relative"}>
                {study === "SPIRIT" ? (
                    hasRolesv2([AppRoles.ADMIN], userRoles) ?
                        <>
                            <Button
                                onClick={handleClick}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: 'start',
                                    textTransform: "none",
                                    color: isActive ? "#004e7c" : "#2e323e",
                                    fontSize: "14px",
                                    "&:hover": { backgroundColor: "transparent" },
                                    "@media (max-width: 1000px)": {
                                        marginLeft: 1,
                                        fontSize: "18px",
                                        padding: "18px",
                                        position: "relative",
                                        width: "100%",
                                    }
                                }}
                            >
                                {isActive ? (
                                    <CastConnectedRoundedIcon sx={activeIcon} />
                                ) : (
                                    <CastOutlinedIcon sx={iconStyle} />
                                )}
                                {t("ROLE_SCREENING")}
                                {isActive && <Box sx={selectedRipple}></Box>}
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => {
                                    navigate('/screening');
                                    handleClose();
                                }}>
                                    <Link
                                        component={RouterLink}
                                        to="/screening"
                                        sx={{
                                            ...navLinkStyle,
                                            height: "30px",
                                        }}
                                        onClick={onClick}
                                        data-testid="screening-link"
                                    >
                                        {isInPath(['/screening']) ? (
                                            <CastConnectedRoundedIcon sx={activeIcon} />
                                        ) : (
                                            <CastOutlinedIcon sx={iconStyle} />
                                        )}
                                        {t("ROLE_SCREENING")}
                                    </Link>
                                    {isInPath(['/screening']) && <Box sx={{ ...selectedRipple, left: 0 }}></Box>}
                                </MenuItem>

                                <MenuItem onClick={() => {
                                    navigate('/group-distribution');
                                    handleClose();

                                }}>
                                    <Link
                                        component={RouterLink}
                                        to="/group-distribution"
                                        sx={{
                                            ...navLinkStyle,
                                            height: "30px",
                                        }}
                                        onClick={onClick}
                                        data-testid="group-distribution-link"
                                    >
                                        {isInPath(['/group-distribution']) ? (
                                            <AssessmentRoundedIcon sx={activeIcon} />
                                        ) : (
                                            <AssessmentOutlinedIcon sx={iconStyle} />
                                        )}
                                        {t("GROUP_DISTRIBUTION")}
                                    </Link>
                                    {isInPath(['/group-distribution']) && <Box sx={{ ...selectedRipple, left: 0 }}></Box>}
                                </MenuItem>
                            </Menu>
                        </> : hasRolesv2([AppRoles.ASSESSOR], userRoles) ? <Link
                            component={RouterLink}
                            to="/screening"
                            sx={{
                                ...navLinkStyle,
                                color: isInPath(['/screening']) ? "#004e7c" : "#2e323e",
                                "&:hover": { backgroundColor: "transparent" },
                                "@media (max-width: 1000px)": {
                                    marginLeft: 1,
                                    width: "100%",
                                    padding: "18px",
                                    fontSize: "18px",
                                    position: "relative",
                                    fontWeight: 500,
                                }
                            }}
                            onClick={onClick}
                            data-testid="screening-link"
                        >
                            {isInPath(['/screening']) ? (
                                <CastConnectedRoundedIcon sx={activeIcon} />
                            ) : (
                                <CastOutlinedIcon sx={iconStyle} />
                            )}
                            {t("ROLE_SCREENING")}
                            {isInPath(['/screening']) && <Box sx={selectedRipple}></Box>}
                        </Link> : <Link
                            component={RouterLink}
                            to="/group-distribution"
                            sx={{
                                ...navLinkStyle,
                                color: isInPath(['/group-distribution']) ? "#004e7c" : "#2e323e",
                                "&:hover": { backgroundColor: "transparent" },
                                "@media (max-width: 1000px)": {
                                    marginLeft: 1,
                                    width: "100%",
                                    padding: "18px",
                                    fontSize: "18px",
                                    position: "relative",
                                    fontWeight: 500,
                                }
                            }}
                            onClick={onClick}
                            data-testid="group-distribution-link"
                        >
                            {isInPath(['/group-distribution']) ? (
                                <AssessmentRoundedIcon sx={activeIcon} />
                            ) : (
                                <AssessmentOutlinedIcon sx={iconStyle} />
                            )}
                            {t("GROUP_DISTRIBUTION")}
                            {isInPath(['/group-distribution']) && <Box sx={selectedRipple}></Box>}
                        </Link>
                ) : (
                    hasRolesv2([AppRoles.ADMIN, AppRoles.ASSESSOR], userRoles) ?
                        <Link
                            component={RouterLink}
                            to="/screening"
                            sx={{
                                ...navLinkStyle,
                                color: isInPath(['/screening']) ? "#004e7c" : "#2e323e",
                                "&:hover": { backgroundColor: "transparent" },
                                "@media (max-width: 1000px)": {
                                    marginLeft: 1,
                                    width: "100%",
                                    padding: "18px",
                                    fontSize: "18px",
                                    position: "relative",
                                    fontWeight: 500,
                                }
                            }}
                            data-testid="screening-link"
                        >
                            {isInPath(['/screening']) ? (
                                <CastConnectedRoundedIcon sx={activeIcon} />
                            ) : (
                                <CastOutlinedIcon sx={iconStyle} />
                            )}
                            {t("ROLE_SCREENING")}
                            {isInPath(['/screening']) && <Box sx={selectedRipple}></Box>}
                        </Link> : null
                )}
            </Box>
        </>
    );
};

export default observer(ScreeningSelector);
