import { Box, Button, Typography } from "@mui/material"
import { SessionDetailsDto, SessionStatus } from "../../../../../data/models/session"
import { RoundButton } from "../../../../../_styles/StyledButtons"
import { useTranslation } from "react-i18next"
import { useStore } from "../../../../../stores/store"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"

interface Props {
    session: SessionDetailsDto,
    onSurveyStart: (surveyId: string) => Promise<void>
}

export default function SessionATSBanner({ session, onSurveyStart }: Props) {
    const { t } = useTranslation();
    const { sessionStore, dialogStore: { openDialog, closeDialog }, institutionStore } = useStore();
    const { loadAssessorSession, atsPostponeToEndOfBaseline, atsPostponeToIntervention, loading } = sessionStore;
    const { selectedUserInstitution, getInstitution } = institutionStore;
    const [study, setStudy] = useState('');

    const postponeToEndOfBaseline = (sessionId: string) => {
        openDialog(
            t("DIALOG_TITLE_POSTPONE_ATS"),
            t("DIALOG_MSG_POSTPONE_BASELINE"),
            async () => {
                await atsPostponeToEndOfBaseline(sessionId);
                await loadAssessorSession(sessionId);
                closeDialog();
                toast.success(t("TOAST_ATS_POSTPONED"));
            },
            closeDialog,
            {
                text: t("POSTPONE"),
                color: "warning",
            },
        )
    }

    const postponeToIntervention = (sessionId: string) => {
        openDialog(
            t("DIALOG_TITLE_POSTPONE_ATS"),
            t("DIALOG_MSG_POSTPONE_INTERVENTION"),
            async () => {
                await atsPostponeToIntervention(sessionId);
                await loadAssessorSession(sessionId);
                closeDialog();
                toast.success(t("TOAST_ATS_POSTPONED"));
            },
            closeDialog,
            {
                text: t("POSTPONE"),
                color: "warning",
            },
        )
    }

    useEffect(() => {
        if (selectedUserInstitution) {
            const fetchInstitution = async () => {
                const institution = await getInstitution(selectedUserInstitution.institutionId);
                setStudy(institution.cantonTeam);
            }

            fetchInstitution();
        }
    }, [getInstitution, selectedUserInstitution]);

    return (
        <>
            <Box className="overview-alert critical-alert">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <div>
                        {study === "SPIRIT" && session.timeline === 'Baseline' &&
                            (
                                session.currentStep === "ASSESSMENT_OF_SUICIDE_ACTIVE" ? <Button
                                    variant="contained"
                                    color="warning"
                                    disabled={loading}
                                    onClick={() => postponeToEndOfBaseline(session?.sessionId)}
                                >
                                    {t("POSTPONE_END_OF_BASELINE")}
                                </Button> : <Button
                                    variant="contained"
                                    color="warning"
                                    disabled={loading}
                                    onClick={() => postponeToIntervention(session?.sessionId)}
                                >
                                    {t("POSTPONE_TO_INTERVENTION")}
                                </Button>
                            )
                        }
                    </div>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography mr={2} color="white">
                            {t("SESSION_ASSESSOR_SURVEY")} Assessment of Thoughts of Suicide
                        </Typography>
                        <RoundButton
                            variant="contained"
                            color="error"
                            onClick={() => onSurveyStart(session?.assessmentOfSuicideSurveyId!)}
                        >
                            {t("GENERAL_START")}
                        </RoundButton>
                    </Box>
                </Box>
            </Box>
        </>
    )
}