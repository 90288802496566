import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { RoundButton } from "../_styles/StyledButtons";
import { useStore } from "../stores/store";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const buttonStlyes = {
    width: 'auto',
    padding: 2.5
}

function ConfirmDeleteDialog() {
    const { t } = useTranslation();
    const { dialogStore: { dialog } } = useStore();
    const { open, title, message, onConfirm, onCancel, button } = dialog;
    const [loading, setLoading] = useState(false);

    const handleConfirm = async () => {
        setLoading(true);
        try {
            await onConfirm();
        } catch (error) {
            onCancel();
            throw error;
        } finally {
            setLoading(false);
        }
    }

    return (
        <Dialog
            onClose={onCancel}
            open={open}
            PaperProps={{
                style: { padding: '1rem' }
            }}
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: '1rem' }}>
                <RoundButton variant="text" color="inherit" sx={buttonStlyes} onClick={onCancel} title="Cancel" data-testid="dialog-cancel">
                    <Typography fontSize="13px">{t("GENERAL_CANCEL")}</Typography>
                </RoundButton>
                <RoundButton variant="contained" color={button.color} sx={buttonStlyes} onClick={handleConfirm} disabled={loading} title={button.text} data-testid="dialog-confirm">
                    {loading ? (
                        <CircularProgress size={25} />
                    ) : (
                        <Typography fontSize="13px">{button.text}</Typography>
                    )}
                </RoundButton>
            </DialogActions>
        </Dialog>
    );
}

export default observer(ConfirmDeleteDialog);