import { Box, CircularProgress, Typography } from "@mui/material";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useStore } from "../../../../../stores/store";
import { RoundButton } from "../../../../../_styles/StyledButtons";
import "../styles.css";
import { useTranslation } from "react-i18next";
import StatusCard from "../../../../../components/StatusCard";
import { observer } from "mobx-react-lite";
import { Group } from "../../../../../data/models/survey";
import FollowUpForm from "../Forms/FollowUpForm";

interface Props {
    shouldDisplayGroup: boolean;
}
function BaselineResults({ shouldDisplayGroup }: Props) {
    const { t } = useTranslation();
    const { sessionStore, modalStore, loadingStore } = useStore();
    const { getSessionTimelines, getAssessorSession, confirmBaselineAllocation, loadAssessorSession, session } = sessionStore;
    const { closeModal, openModal } = modalStore;
    const { isLoading } = loadingStore;
    const [group, setGroup] = useState<{ group: string, subgroup: string }>({ group: "-", subgroup: "-" })

    const { sessionId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            if (sessionId) {
                const session = await getAssessorSession(sessionId)
                setGroup({ group: session.group ?? "-", subgroup: session.subgroup ?? "-" });
            }
        }

        fetchData();
    }, [getAssessorSession, getSessionTimelines, sessionId]);

    const onConfirm = async () => {
        if (sessionId) {
            await confirmBaselineAllocation(sessionId);
            await loadAssessorSession(sessionId);
            closeModal();
        }
    }

    const openFollowUpTypeDialog = async () => {
        if (session) {
            openModal(
                <FollowUpForm
                    nextTimeline={""}
                    sessionId={session.sessionId}
                    previousValue={session.assessmentType}
                />,
                { size: "xs" }
            );
        }
    }

    const isGroupC = session?.group?.toLowerCase() === Group.C.toLowerCase();

    if (shouldDisplayGroup) {
        return (
            <Box>
                <Box>
                    <Typography fontWeight={600} fontSize={"1.25rem"}>{t("BL_RESULTS")}</Typography>
                </Box>
                <StatusCard
                    header={t("GENERAL_GROUP")}
                    data={group.group.replace("Group ", "")}
                    style={{
                        boxShadow: "none !important"
                    }}
                />
                {//currently not displaying subgroup, comment it out if the subgroup is needed
                    /* <StatusCard
                            header={t("BL_SUBSAMPLE")}
                            data={group.subgroup.replace("Subsample ", "")}
                            style={{
                                boxShadow: "none !important"
                            }}
                        /> */
                }
                <Box display={"flex"} justifyContent={"flex-end"} mt="10px">
                    <RoundButton
                        variant="contained"
                        disableTouchRipple
                        disableFocusRipple
                        onClick={() => {
                            closeModal();
                            openFollowUpTypeDialog()
                        }}
                    >
                        {t("GENERAL_CLOSE")}
                    </RoundButton>
                </Box>
            </Box>
        )
    }
    else
        return (
            <Box>
                <Box>
                    <Typography fontWeight={600} fontSize={"1.25rem"}> {isGroupC ? t("CLIENT_DIDNT_REACH_CUT_OFF") : t("CLIENT_REACH_CUT_OFF")}</Typography>
                    <Typography> {isGroupC ? t("PLEASE_INFORM_CLIENT_ABOUT_NEXT_STEPS") : t("PLEASE_INFORM_CLIENT_ABOUT_2ND_IC")}</Typography>
                </Box>
                <Box display={"flex"} justifyContent={"flex-end"} mt="10px">
                    <RoundButton
                        variant="contained"
                        disableTouchRipple
                        disableFocusRipple
                        onClick={onConfirm}
                        disabled={isLoading([confirmBaselineAllocation])}
                    >
                        {isLoading([confirmBaselineAllocation]) ?
                            <CircularProgress color="secondary" size={25} />
                            :
                            t("GENERAL_CONFIRM")
                        }
                    </RoundButton>
                </Box>
            </Box>
        );
}

export default observer(BaselineResults);