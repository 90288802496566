import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, CircularProgress, Menu, Typography } from "@mui/material";

import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { RoundButton } from "../../../_styles/StyledButtons";
import { StyledMenuItem } from "../../../_styles/StyledMenu";
import { AssessorEventStatus, CalendarEvent, EventConfirmDto, EventDeleteDto, View } from "../../../data/models/event";
import { AppRoles, ModuleName, PermissionName } from "../../../data/models/role";
import { useStore } from "../../../stores/store";
import { getRRuleEditEndDate, getRRuleEditStartDate } from "../../../utils/eventRecurrence";
import { getModulePermissions, hasRolesv2 } from "../../../utils/permissionEvaluator";
import EventForm from "./EventForm";
import EventStatusDisplay from "./EventStatusDisplay";

type ActionType = "edit" | "delete" | "confirm-schedule" | "confirm" | "decline";

interface EventActionMenuProps {
    eventData: null | undefined | CalendarEvent;
    isOpen: (open: boolean) => void;
    timelineStarted: boolean;
    isAbsent: boolean;
}

function EventActionMenu({ eventData, isOpen, timelineStarted, isAbsent }: EventActionMenuProps) {
    const { t } = useTranslation();
    const {
        bookingStore: { deleteEvent, getEventById, getEventTypes, addExceptionEvent, getEventsByUserId, userEventsId, confirmScheduledAppointment, confirmEvent, getUserEvents, getEvents, getRoomEvents, updateEvent },
        dialogStore: { openDialog, closeDialog },
        tenantStore: { selectedTenant },
        roomStore: { getRooms },
        institutionStore: { selectedUserInstitution },
        modalStore: { openModal },
        commonStore: { getCalendarViews },
        loadingStore: { isLoading }
    } = useStore();
    const [userEventIds, setUserEventIds] = useState<string[]>([]);

    const [hasSeries, setHasSeries] = useState(false);
    const [type, setType] = useState<ActionType | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        setAnchorEl(null);
        setType(null);
        setHasSeries(eventData?.recurrence !== 'no-repeat');
        const eventIdList = getEventsByUserId(userEventsId ?? "").map(e => e.id);
        setUserEventIds(eventIdList);
    }, [eventData, getEventsByUserId, userEventsId])

    useEffect(() => {
        if (!hasSeries) {
            handleEventAction(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    const handleClick = (event: React.MouseEvent<HTMLElement>, type: ActionType) => {
        setType(type);
        if (hasSeries) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = async (allSeries: boolean) => {
        const event = await getEventById(eventData?.id!);
        const startDate = allSeries
            ? getRRuleEditStartDate(event)
            : eventData?.start!;
        const endDate = allSeries
            ? getRRuleEditEndDate(event)
            : eventData?.end!;

        if (selectedTenant) {
            await getEventTypes(selectedTenant.id);
            await getRooms(selectedTenant.id);
        }

        openModal(
            <EventForm
                event={event}
                initialStartDate={startDate!}
                initialEndDate={
                    event.isAllDay
                        ? dayjs(endDate!).subtract(1, "day").toDate()
                        : endDate!
                }
                allRecurrences={allSeries}
            />,
            {
                confirmClose: true
            }
        );
        isOpen(false);
    };

    const handleDelete = (allSeries: boolean) => {
        const eventObject: EventDeleteDto = {
            eventId: eventData?.id as string,
            eventDate: new Date(),
            startDate: eventData?.start as Date
        };
        const date = allSeries ? null : eventData?.start.toISOString();
        let message = allSeries ? t("DIALOG_MSG_EVENT_ALL") : t("DIALOG_MSG_EVENT");
        message += " " + t("DIALOG_MSG_EVENT_ACTION");

        openDialog(
            t("DIALOG_TITLE_DELETE"),
            message,
            async () => {
                allSeries || eventData?.recurrence === 'no-repeat' ? await deleteEvent(eventObject, date!) : await addExceptionEvent(eventObject.eventId, date!);
                closeDialog();
                toast.success(t("TOAST_EVENT_DELETE"));
                isOpen(false);
            },
            closeDialog,
            {
                text: t("GENERAL_DELETE"),
                color: 'error'
            }
        );
    };

    const handleEventScheduleConfirm = async () => {

        await confirmScheduledAppointment(eventData?.id!, selectedUserInstitution?.institutionId!)
        isOpen(false);
        toast.success(t("TOAST_SCHEDULE_CONFIRMED"));
        getCalendarViews().forEach(async (view) => {
            if (view.type === View.USER) {
                if (view.id === userEventsId) {
                    await getUserEvents();
                } else {
                    await getEvents(view.id);
                }
            } else {
                await getRoomEvents(view.id);
            }
        });
    };

    const handleEventConfirm = async () => {
        const eventObject: EventConfirmDto = {
            status: "CONFIRMED"
        };


        await confirmEvent(eventData?.id!, eventObject)
        isOpen(false);
        toast.success(t("TOAST_SCHEDULE_CONFIRMED"));
        getCalendarViews().forEach(async (view) => {
            if (view.type === View.USER) {
                if (view.id === userEventsId) {
                    await getUserEvents();
                } else {
                    await getEvents(view.id);
                }
            } else {
                await getRoomEvents(view.id);
            }
        });
    };

    const handleDecline = async () => {
        const eventObject: EventConfirmDto = {
            status: "DECLINED"
        };

        await confirmEvent(eventData?.id!, eventObject)
        isOpen(false);
        toast.success(t("TOAST_SCHEDULE_DECLINED"));
        getCalendarViews().forEach(async (view) => {
            if (view.type === View.USER) {
                if (view.id === userEventsId) {
                    await getUserEvents();
                } else {
                    await getEvents(view.id);
                }
            } else {
                await getRoomEvents(view.id);
            }
        });
    };

    const handleEventAction = (isForAllSeries: boolean) => {
        switch (type) {
            case "edit":
                handleEdit(isForAllSeries);
                break;
            case "delete":
                handleDelete(isForAllSeries);
                break;
            case "confirm-schedule":
                handleEventScheduleConfirm();
                break;
            case "confirm":
                handleEventConfirm();
                break;
            case "decline":
                handleDecline();
        }
        handleClose();
    };

    const hasDeletePermission = () => {
        const userPermissions = getModulePermissions(ModuleName.RESOURCE_PLANNING, selectedUserInstitution!)
            .filter(p => p.includes("DELETE"));

        if (userPermissions.includes(PermissionName.DELETE_ALL)) return true;

        if (userPermissions.includes(PermissionName.DELETE_OWN) && userEventIds.includes(eventData?.id ?? "")) return true;

        return false;
    }

    const hasEditPermission = () => {
        const userPermissions = getModulePermissions(ModuleName.RESOURCE_PLANNING, selectedUserInstitution!)
            .filter(p => p.includes("EDIT"));

        if (userPermissions.includes(PermissionName.EDIT_ALL)) return true;

        if (userPermissions.includes(PermissionName.EDIT_OWN) && userEventIds.includes(eventData?.id ?? "")) return true;

        return false;
    }


    return (
        <Box sx={{ display: "flex", flex: 1, justifyContent: "end" }}>
            {hasEditPermission() && (
                <Box>
                    <RoundButton
                        onClick={(e) => handleClick(e, "edit")}
                        variant="outlined"
                        sx={{ width: "auto" }}
                        color="primary"
                        title={t("GENERAL_EDIT")}
                        data-testid="editEvent-button"
                        endIcon={hasSeries ? <KeyboardArrowDownIcon /> : null}
                        disabled={isLoading(updateEvent) || isLoading(getEvents)}
                    >
                        {isLoading(updateEvent) && type === "edit" ? (
                            <CircularProgress color="secondary" size={25} />
                        ) : (
                            <Typography fontSize="13px">{t("GENERAL_EDIT")}</Typography>
                        )}
                    </RoundButton>
                </Box>
            )}
            <Box sx={{ pl: 1 }}>
                {hasDeletePermission() && (
                    <RoundButton
                        variant="contained"
                        color="error"
                        sx={{ width: "auto" }}
                        onClick={(e) => handleClick(e, "delete")}
                        title={t("GENERAL_DELETE")}
                        data-testid="deleteEvent-button"
                        disabled={isLoading(getEvents)}
                        endIcon={hasSeries ? <KeyboardArrowDownIcon /> : null}
                    >
                        <Typography fontSize="13px">{t("GENERAL_DELETE")}</Typography>
                    </RoundButton>
                )}
            </Box>
            <Box sx={{ pl: 1 }}>
                {hasEditPermission()
                    && (eventData?.eventType === 'ASSESSMENT' || eventData?.eventType === 'INTERVENTION')
                    && !eventData.isConfirmed &&
                    !timelineStarted &&
                    !isAbsent && (
                        <RoundButton
                            variant="contained"
                            color="primary"
                            sx={{ width: "auto" }}
                            onClick={(e) => handleClick(e, "confirm-schedule")}
                            title={t("GENERAL_CONFIRM")}
                            data-testid="confirm-popper-button"
                            disabled={isLoading(confirmScheduledAppointment)}
                        >
                            {isLoading(confirmScheduledAppointment) && type === "confirm-schedule" ? (
                                <CircularProgress color="primary" size={25} />
                            ) : (
                                <Typography fontSize="13px">{t("GENERAL_CONFIRM")}</Typography>
                            )}
                        </RoundButton>
                    )}
                {
                    hasRolesv2([AppRoles.ASSESSOR, AppRoles.HELPER], selectedUserInstitution?.roles ?? []) &&
                    (eventData?.eventType === 'ASSESSMENT' || eventData?.eventType === 'INTERVENTION') &&
                    !timelineStarted &&
                    !isAbsent &&
                    eventData?.isConfirmed &&
                    <>
                        <RoundButton
                            variant="outlined"
                            color="error"
                            sx={{ width: "auto" }}
                            onClick={(e) => handleClick(e, "decline")}
                            title={t("GENERAL_DELETE")}
                            data-testid="deleteEvent-button"
                            disabled={isLoading(getEvents)}
                            endIcon={hasSeries ? <KeyboardArrowDownIcon /> : null}
                        >
                            <Typography fontSize="13px">{t("DECLINE")}</Typography>
                        </RoundButton>
                        <RoundButton
                            variant="contained"
                            color="primary"
                            sx={{ width: "auto", ml: 1 }}
                            onClick={(e) => handleClick(e, "confirm")}
                            title={t("GENERAL_CONFIRM")}
                            data-testid="confirm-popper-button"
                            disabled={isLoading(confirmScheduledAppointment)}
                        >
                            {isLoading(confirmScheduledAppointment) && type === "confirm" ? (
                                <CircularProgress color="primary" size={25} />
                            ) : (
                                <Typography fontSize="13px">{t("GENERAL_CONFIRM")}</Typography>
                            )}
                        </RoundButton>
                    </>
                }
            </Box>
            <Menu
                id="event-menu-actions"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transitionDuration={0}
                slotProps={{
                    backdrop: {
                        invisible: true
                    }
                }}
            >
                <StyledMenuItem onClick={() => handleEventAction(false)} disableRipple>
                    {t('EVENT_ACTION_SINGLE')}
                </StyledMenuItem>
                <StyledMenuItem onClick={() => handleEventAction(true)} disableRipple>
                    {t('EVENT_ACTION_SERIES')}
                </StyledMenuItem>
            </Menu>
        </Box>
    );
}

export default observer(EventActionMenu);