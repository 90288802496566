export interface ProcessFormValues {
    name: string,
    surveyIds: string[],
    isASAP: boolean,
    startTime: Date,
    startDate: Date,
    endTime: Date,
    endDate: Date,
    reminder: string
}

export interface ProcessDto {
    id: string,
    name: string,
    startTime: Date,
    endTime: Date,
    status: string
}

export enum ProcessStatus {
    DRAFT = "DRAFT",
    PUBLISHED = "PUBLISHED",
    ARCHIVED = "ARCHIVED"
}

export interface ProcessDetails {
    id: string;
    name: string;
    startTime: Date;
    endTime: Date;
    status: ProcessStatus;
    timelines: ProcessTimeline[];
    clientIds: string[];
    groupDistribution: Array<{ id: string, group: string, subsample?: string, count: number }>,
    selectedGroupDistributionId?: string
}

export interface ProcessTimeline {
    id: string;
    name: string;
    monthsTo: number;
    surveys: ProcessSurvey[];
}

export interface ProcessSurvey {
    id: string;
    name: string;
}

export interface ProcessClientsAddDto {
    processId: string;
    clientIds: string[];
    institutionId: string;
}