import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Box, Button, Menu, MenuItem, Link } from "@mui/material";
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../stores/store';

interface LogSelectorProps {
    isSuperAdmin: boolean | undefined;
    navLinkStyle: object;
    iconStyle: object;
    activeIcon: object;
    selectedRipple?: object;
    onClick?: () => void;
}

const LogSelector: React.FC<LogSelectorProps> = ({
    isSuperAdmin,
    navLinkStyle,
    iconStyle,
    activeIcon,
    selectedRipple,
    onClick
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const isInPath = (paths: string[]) => paths.some(path => location.pathname.startsWith(path));

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isActive = isInPath(['/logs', '/notification-logs']);

    return (
        <Box display={"flex"} position={"relative"}>
            {isSuperAdmin ? (
                <>
                    <Button
                        onClick={handleClick}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: 'start',
                            textTransform: "none",
                            color: isActive ? "#004e7c" : "#2e323e",
                            fontSize: "14px",
                            "&:hover": { backgroundColor: "transparent" },
                            "@media (max-width: 1000px)": {
                                marginLeft: 1,
                                fontSize: "18px",
                                padding: "18px",
                                position: "relative",
                                width: "100%",
                            }


                        }}
                    >
                        {isActive ? (
                            <AssessmentRoundedIcon sx={activeIcon} />
                        ) : (
                            <AssessmentOutlinedIcon sx={iconStyle} />
                        )}
                        {t("NAV_LOGS")}
                        {isActive && <Box sx={selectedRipple}></Box>}
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => {
                            navigate('/logs');
                            handleClose();
                        }}>
                            <Link
                                component={RouterLink}
                                to="/logs"
                                sx={{
                                    ...navLinkStyle,
                                    height: "30px",
                                }}
                                onClick={onClick}
                                data-testid="logs-link"
                            >
                                {isInPath(['/logs']) ? (
                                    <AssessmentRoundedIcon sx={activeIcon} />
                                ) : (
                                    <AssessmentOutlinedIcon sx={iconStyle} />
                                )}
                                {t("NAV_AUDIT_LOGS")}
                            </Link>
                            {isInPath(['/logs']) && <Box sx={{ ...selectedRipple, left: 0 }}></Box>}
                        </MenuItem>

                        <MenuItem onClick={() => {
                            navigate('/notification-logs');
                            handleClose();

                        }}>
                            <Link
                                component={RouterLink}
                                to="/notification-logs"
                                sx={{
                                    ...navLinkStyle,
                                    height: "30px",
                                }}
                                onClick={onClick}
                                data-testid="notification-logs-link"
                            >
                                {isInPath(['/notification-logs']) ? (
                                    <AssessmentRoundedIcon sx={activeIcon} />
                                ) : (
                                    <AssessmentOutlinedIcon sx={iconStyle} />
                                )}
                                {t("NOTIFICATIONS_LOGS")}

                            </Link>
                            {isInPath(['/notification-logs']) && <Box sx={{ ...selectedRipple, left: 0 }}></Box>}
                        </MenuItem>
                    </Menu>
                </>
            ) : (
                <Link
                    component={RouterLink}
                    to="/notification-logs"
                    sx={{
                        ...navLinkStyle,
                        color: isInPath(['/logs']) ? "#004e7c" : "#2e323e",
                        "&:hover": { backgroundColor: "transparent" },
                        "@media (max-width: 1000px)": {
                            marginLeft: 1,
                            width: "100%",
                            padding: "18px",
                            fontSize: "18px",
                            position: "relative",
                            fontWeight: 500,
                        }
                    }}
                    onClick={onClick}
                    data-testid="notification-logs-link"
                >
                    {isInPath(['/notification-logs']) ? (
                        <AssessmentRoundedIcon sx={activeIcon} />
                    ) : (
                        <AssessmentOutlinedIcon sx={iconStyle} />
                    )}
                    {t("NOTIFICATIONS_LOGS")}
                    {isInPath(['/notification-logs']) && <Box sx={selectedRipple}></Box>}
                </Link>
            )
            }
        </Box >
    );
};

export default observer(LogSelector);
