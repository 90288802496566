import { Box, IconButton, Menu, Skeleton, Typography } from "@mui/material";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { useState } from "react";

import TenantInstitutionList from "../pages/User/Profile/TenantInstitutionList";
import { UserTenant } from "../data/models/tenant";
import { useStore } from "../stores/store";
import "./styles.css";
import ConfirmDialog from "./ConfirmDialog";

function TenantListMenu() {
    const {
        profileStore: { userTenantList },
        tenantStore: { selectedTenant },
        institutionStore: { selectedUserInstitution },
    } = useStore();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getInstitutionName = () => {
        return userTenantList.map(tenant => tenant.institutions.filter(
            (i) => i.id === selectedUserInstitution?.institutionId
        )[0]?.name)
    }

    return (
        <Box mr={1} className="nav-tenant-menu-container">
            <IconButton
                size="large"
                onClick={handleMenu}
                title="Tenant list"
                data-testid="tenant-list-menu-select"
                className="tenant-menu-button"
                sx={{ borderRadius: "1rem", padding: "8px", fontSize: "13px" }}
                disableRipple
            >
                <CorporateFareOutlinedIcon sx={{ color: "#66717d", ml: "7px", fontSize: "20px" }} />
                <Typography className="navbar-menu-font navbar-tenant-list tenant-list-text">
                    {getInstitutionName() || <Skeleton variant="text" sx={{ fontSize: "1rem", width: '75px' }} />}
                </Typography>
                <ArrowDropDownIcon
                    sx={{
                        color: "#66717d",
                        transform: anchorEl ? "rotate(-180deg)" : "none",
                        transition: "transform 0.3s ease",
                    }}
                />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                transitionDuration={0}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                PaperProps={{
                    style: {
                        maxHeight: "calc(100% - 64px)",
                    },
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {userTenantList.map((tenant: UserTenant) => (
                    <TenantInstitutionList
                        handleClose={handleClose}
                        key={tenant.id}
                        tenant={tenant}
                    />
                ))}
            </Menu>
            <ConfirmDialog />
        </Box>
    );
}

export default TenantListMenu;
