import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import ConfirmDeleteDialog from "../../../../components/ConfirmDialog";
import GenericTable from '../../../../components/GenericTable';
import { SessionActionDto, StudyOverviewDTO } from '../../../../data/models/studyOverview';
import { getGenericTableStyling } from '../../../../utils/utils';
import ClientSessionActionDialog from '../../Form/ClientSessionActionDialog';
import { studyOverviewColumns } from './studyOverviewColumns';
import StudyOverviewToolbar from './StudyOverviewToolbar';
import { useStudyOverview } from './useStudyOverview';
import { useStudyOverviewActions } from './useStudyOverviewActions';
import { useNavigate } from 'react-router-dom';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useStore } from '../../../../stores/store';
import CustomPopper from './StatusPopper';
import { t } from 'i18next';
import { Cancel, CheckCircle, Info } from '@mui/icons-material';
import { blue, green, red } from '@mui/material/colors';

const StudyOverview: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        studyOverviewList,
        handlePageChange,
        handleSearchChange,
        handleGroupChange,
        isTableLoading,
        rowCount,
        institutionStudyType,
        fetchStudyOverviewData
    } = useStudyOverview();

    const {
        handleStatusChange,
        onNoShow,
        displaySessionActionDialog,
    } = useStudyOverviewActions(fetchStudyOverviewData, institutionStudyType);

    const popperAnchorRef = useRef<HTMLButtonElement>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [popperOpener, setPopperOpener] = useState(false);
    const [clientId, setClientId] = useState<string>('');

    const handleStatusSelect = (e: SyntheticEvent<HTMLElement>, clientId: string) => {
        setAnchorEl(e.currentTarget);
        setClientId(clientId);
        setPopperOpener(true);
    };

    return (
        <Container maxWidth="xl" sx={{ my: 5 }}>
            <Typography sx={{ fontWeight: '700', fontSize: '28px' }} marginBottom={3}>{t("COORDINATION_OVERVIEW")}</Typography>
            <GenericTable<StudyOverviewDTO>
                toolbar={<StudyOverviewToolbar onSearch={handleSearchChange} onGroupChange={handleGroupChange} isLoading={isTableLoading} />}
                columns={studyOverviewColumns(t, handleStatusChange, onNoShow, navigate, popperAnchorRef, handleStatusSelect)}
                rows={studyOverviewList}
                rowKey="clientId"
                props={getGenericTableStyling()}
                usePagination
                loading={isTableLoading}
                onPaginationModelChange={handlePageChange}
                rowCount={rowCount}
            />
            {displaySessionActionDialog ? <ClientSessionActionDialog /> : <ConfirmDeleteDialog />}
            <CustomPopper
                open={popperOpener}
                anchorElement={anchorEl}
                children={<StatusHistory clientId={clientId} />}
                close={() => setPopperOpener(false)}
            />
        </Container>
    );
};

export default observer(StudyOverview);

function StatusHistory({ clientId }: { clientId: string }) {
    const { studyOverviewStore: { getClientSessionHistory }, loadingStore: { isLoading } } = useStore();
    const [list, setList] = useState<string[]>([]);
    useEffect(() => {
        getClientSessionHistory(clientId).then((data) => {
            setList(data ?? []);
        }).catch((error) => { throw error; })
    }, [clientId])

    if (isLoading(getClientSessionHistory)) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100px">
                <CircularProgress size={24} />
            </Box>
        );
    }

    return (
        <>
            {list.length === 0 ? <i>{t("NO_ACTION_SHOW")}</i> :
                list.map((action, index) => (
                    <Box key={index} display="flex" alignItems="center" gap={1} mb={1}>
                        {/* Dot indicator */}
                        <Box
                            component="span"
                            sx={{
                                width: 5,
                                height: 5,
                                borderRadius: '50%',
                                backgroundColor: "black",
                                display: 'inline-block'
                            }}
                        />
                        <Typography variant="body2">
                            {t(action)}
                        </Typography>
                    </Box>
                ))}
        </>
    );
}