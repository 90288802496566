import { Container, Box, ThemeProvider, Typography } from "@mui/material";

import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import { useIdleTimer } from 'react-idle-timer'

import 'react-toastify/dist/ReactToastify.css';
import LoadingComponent from "./components/LoadingComponent";
import { theme } from "./theme";
import { useStore } from "./stores/store";
import NavBar from "./components/navbar/NavBar";
import ModalContainer from "./components/modal/ModalContainer";
import LoginContainer from "./pages/User/Login/LoginContainer";

function App() {
    const location = useLocation();
    const { commonStore, authStore } = useStore();
    const idleTimeLimit = 30 * 60 * 1000; // 30 minutes

    const onIdle = () => {
        authStore.updateState("idle");
    }

    const onActive = () => {
        authStore.updateState("active");
    }

    useIdleTimer({
        onIdle,
        onActive,
        timeout: idleTimeLimit,
        crossTab: true,
        throttle: 500
    })

    useEffect(() => {
        if (commonStore.token) {
            authStore.getCurrentUser().finally(() => commonStore.setAppLoaded());
        } else {
            commonStore.setAppLoaded();
        }
    }, [commonStore, authStore])

    if (!commonStore.appLoaded) return (
        <ThemeProvider theme={theme}>
            <LoadingComponent />
        </ThemeProvider>
    )

    return (
        <Box>
            <ModalContainer />
            <Typography component="h5" variant="h5" className="project-version">v2.16.0-{process.env.REACT_APP_ENVIRONMENT}</Typography>
            <ThemeProvider theme={theme}>
                {authStore.isLoggedIn && <NavBar />}
                <Container maxWidth={false} className="app-container">
                    {location.pathname === '/' ? <LoginContainer /> : (
                        <Outlet />
                    )}
                </Container>
                <ToastContainer position="bottom-right" theme='colored' />
            </ThemeProvider>
        </Box>
    );
}

export default observer(App);
