import { Divider, Fade, Grid, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box/Box";
import Popper from "@mui/material/Popper/Popper";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import DoorSlidingOutlinedIcon from "@mui/icons-material/DoorSlidingOutlined";

import { observer } from "mobx-react-lite";
import { format } from "date-fns";

import { AssessorEventStatus, CalendarEvent, View } from "../../../data/models/event";
import { useStore } from "../../../stores/store";
import ConfirmDeleteDialog from "../../../components/ConfirmDialog";
import { hasModulePermission, hasRoles, hasRolesv2 } from "../../../utils/permissionEvaluator";
import { AppRoles, ModuleName, PermissionName } from "../../../data/models/role";
import "../styles.css";
import AttendeeList from "./AttendeeList";
import { useTranslation } from "react-i18next";
import EventActionMenu from "./EventActionMenu";
import { useEffect, useState } from "react";
import { capitalizeFirstLetter, getDateAndTimeFormatByPlatformLanguage } from "../../../utils/utils";
import { RoundButton } from "../../../_styles/StyledButtons";
import ScreeningEventAttendees from "./ScreeningEventAttendees";
import EventStatusDisplay from "./EventStatusDisplay";
import EventStatus from "../Options/EventStatus";

interface Props {
    open: boolean;
    anchorElement: null | HTMLElement;
    eventData: null | undefined | CalendarEvent;
    handleOpen: (open: boolean) => void;
}

const popperModifiers = [
    {
        name: "flip",
        enabled: true,
        options: {
            altBoundary: true,
            rootBoundary: "document",
            padding: 8,
        },
    },
    {
        name: "preventOverflow",
        enabled: true,
        options: {
            altAxis: true,
            altBoundary: false,
            tether: true,
            rootBoundary: "viewport",
            padding: 8,
        },
    },
];

const iconColor = { color: "#0000008a" };

function EventPopper({
    open = false,
    anchorElement = null,
    eventData,
    handleOpen,
}: Props) {
    const { t, i18n } = useTranslation();
    const {
        institutionStore: { selectedUserInstitution },
        bookingStore: { noShowUp, getEvents, getUserEvents, getRoomEvents, userEventsId },
        sessionStore: { hasTimelineStarted },
        commonStore: { getCalendarViews },
        loadingStore: { isLoading },
        authStore: { user },
        dialogStore: { openDialog, closeDialog },
        modalStore: { closeModal, modal, openModal }
    } = useStore();

    const [started, setStarted] = useState(false);
    const isScreeningEvent = ["ASSESSMENT", "INTERVENTION"].includes(
        eventData?.eventType ?? ""
    );
    useEffect(() => {
        const fetchData = async () => {
            if (!eventData) {
                setStarted(false);
                return;
            }
            const hasStarted = await hasTimelineStarted(eventData.id);
            setStarted(hasStarted);
        };

        fetchData();
    }, [eventData, hasTimelineStarted]);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            const popperElement = document.querySelector("#popper-anchor");
            const menuActions = document.querySelector("#event-menu-actions");

            if (
                popperElement &&
                !popperElement.contains(event.target as Node) &&
                !menuActions
            ) {
                handleOpen(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Handles the action for a no-show event.
     * If event data is available, it opens a dialog for confirmation and executes the necessary actions upon confirmation.
     * 
     * @returns {Promise<void>} A Promise that resolves when the handling is completed.
     */
    const handleNoShow = async (): Promise<void> => {
        if (!eventData) return;

        const confirmAndHandleNoShow = async () => {
            await noShowUp(eventData.id);
            const view = getCalendarViews().find(view => view.id === eventData.viewId);
            if (!view) return;

            if (view.type === View.USER) {
                if (view.id === userEventsId) {
                    await getUserEvents();
                } else {
                    await getEvents(view.id);
                }
            } else {
                await getRoomEvents(view.id);
            }
            closeDialog();
            handleOpen(false);
        };

        openDialog(
            t("SESSION_NOSHOW_TITLE"),
            t("SESSION_NOSHOW_DESC"),
            confirmAndHandleNoShow,
            closeDialog,
            {
                text: t("GENERAL_CONFIRM"),
                color: "warning",
            }
        );
    };


    /**
     * Determines whether to show the attendance button based on event data and user's attendance status.
     * @returns {boolean} Returns true if the attendance button should be shown, otherwise false.
     */
    const showAttendanceButton = (): boolean => {
        if (!eventData) return false;

        const hasEventStarted = new Date(eventData.start) < new Date();
        const assessor = eventData.attendeesStatus.find(
            (attendee) => attendee.id === user?.id
        );

        return hasEventStarted && isScreeningEvent && (!!assessor) && !started;
    };

    /**
     * Determines whether the absence label should be displayed based on event type and user attendance status.
     * @returns {boolean} Returns true if the absence label should be displayed, otherwise false.
     */
    const isAbsent = (): boolean => {
        if (!eventData) return true;

        const invitedPerson = eventData.attendeesStatus.find(
            (attendee) => attendee.id !== user?.id
        );

        return isScreeningEvent && !invitedPerson?.showedUp
    }

    const onRoomClick = (roomNotes?: string) => {
        openModal(
            <div>
                <h3>Room notes</h3>
                {roomNotes
                    ? roomNotes.split('\n').map((line, index) => (
                        <p key={index}>{line}</p>
                    ))
                    : <p><i>No notes for this room</i></p>
                }
            </div>,
            { size: "sm", confirmClose: false }
        );
    };

    return (
        <>
            <Popper
                id="popper-anchor"
                transition
                placement="left-start"
                disablePortal={true}
                open={open}
                anchorEl={anchorElement}
                modifiers={popperModifiers}
                className="event-popper"
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Box
                            sx={{
                                boxShadow:
                                    "rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px",
                                p: 1,
                                backgroundColor: "#ffffff",
                                display: "flex",
                                flexDirection: "column",
                                width: 350,
                            }}
                        >
                            <Grid container gap={1}>
                                <Grid container columnGap={1}>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={10}>
                                        <Box
                                            display={"flex"}
                                            justifyContent={"space-between"}
                                            alignItems={"center"}
                                        >
                                            <Box>
                                            <Typography sx={{ fontSize: 20, fontWeight: "500" }}>
                                                {eventData?.title}
                                            </Typography>
                                            {isAbsent() && <Typography fontSize={"10px"} ml={0.5}>{t("EVENT_NO_SHOW")}</Typography>}
                                            </Box>
                                            {
                                                !hasRoles(["Client"], selectedUserInstitution?.roles ?? [], false) &&
                                                !isAbsent() &&
                                                showAttendanceButton() &&
                                                eventData?.eventStatus === "CONFIRMED" && (
                                                    <RoundButton
                                                        variant="text"
                                                        onClick={handleNoShow}
                                                        sx={{
                                                            textTransform: "none",
                                                            fontWeight: 400,
                                                        }}
                                                    >
                                                        {t("SESSION_NOSHOW")}
                                                    </RoundButton>
                                                )
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ paddingLeft: 4 }}>
                                    <Divider />
                                </Grid>
                                <Grid container columnGap={1}>
                                    <Grid item xs={1}>
                                        <Tooltip title="Event type">
                                            <EventNoteOutlinedIcon sx={iconColor} />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography fontSize={16}>
                                            {t(eventData?.eventType ?? "")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container columnGap={1}>
                                    <Grid item xs={1}>
                                        <Tooltip title="Time">
                                            <AccessTimeOutlinedIcon sx={iconColor} />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={10}>
                                        {eventData?.isAllDay ? (
                                            <Typography fontSize={16}>
                                                {getDateAndTimeFormatByPlatformLanguage(eventData?.start, i18n.language)} {"("}
                                                {t("EVENT_ALL_DAY")}
                                                {")"}
                                            </Typography>
                                        ) : (
                                            <Typography fontSize={16}>
                                                {getDateAndTimeFormatByPlatformLanguage(eventData?.start!, i18n.language)}{" "}
                                                {t("GENERAL_TO")}{" "}
                                                {format(eventData?.end as Date, "HH:mm")}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container columnGap={1}>
                                    <Grid item xs={1}>
                                        <Tooltip title="Tenant">
                                            <BusinessOutlinedIcon sx={iconColor} />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography fontSize={16}>
                                            {eventData?.tenantName ?? "- - -"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container columnGap={1}>
                                    <Grid item xs={1}>
                                        <Tooltip title="Room">
                                            <DoorSlidingOutlinedIcon sx={iconColor} />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography
                                            fontSize={16}
                                            onClick={eventData?.room !== null ? () => onRoomClick(eventData?.roomNotes) : undefined}
                                            sx={{
                                                cursor: "pointer",
                                                padding: "4px 8px",
                                                borderRadius: "4px",
                                                "&:hover": {
                                                    backgroundColor: "lightgrey",
                                                    margin: 0,
                                                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                                                },
                                                "&:focus": {
                                                    outline: "2px solid blue",
                                                    backgroundColor: "lightgrey",
                                                },
                                                transition: "background-color 0.3s, box-shadow 0.3s",
                                            }}
                                        >
                                            {eventData?.room ?? "- - -"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ paddingLeft: 4 }}>
                                    <Divider />
                                </Grid>
                                <Grid container columnGap={1}>
                                    <Grid item xs={1}>
                                        <Tooltip title="Description">
                                            <ArticleOutlinedIcon sx={iconColor} />
                                        </Tooltip>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={10}
                                        sx={{
                                            maxHeight: "30vh",
                                            height: "auto",
                                            overflowY: "auto",
                                            wordWrap: "break-word",
                                        }}
                                    >
                                        <Typography fontSize={16}>
                                            {eventData?.description === ""
                                                ? t("EVENT_NO_DESCRIPTION")
                                                : eventData?.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {
                                    !isScreeningEvent
                                        ? <Grid container columnGap={1}>
                                            <Grid>
                                                <AttendeeList attendees={eventData?.attendeesStatus!} />
                                            </Grid>
                                        </Grid>
                                        : ''
                                }
                                {isScreeningEvent && <Grid container columnGap={1}>
                                    <Grid>
                                        <ScreeningEventAttendees attendees={eventData?.attendeesStatus!} />
                                    </Grid>
                                </Grid>}
                                {eventData &&
                                    <Grid container columnGap={1}>
                                        <EventStatusDisplay eventStatus={eventData.eventStatus} t={t} sx={{ py: 2.0, m: 1 }} />
                                    </Grid>
                                }
                                {(hasModulePermission(
                                    [PermissionName.EDIT_ALL],
                                    ModuleName.RESOURCE_PLANNING,
                                    selectedUserInstitution!,
                                    false
                                ) ||
                                    hasModulePermission(
                                        [PermissionName.EDIT_OWN],
                                        ModuleName.RESOURCE_PLANNING,
                                        selectedUserInstitution!,
                                        false
                                    )) &&
                                    eventData?.start! > new Date() && (
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    )}
                                <Box sx={{ display: "flex", flex: 1 }}>
                                    <EventActionMenu eventData={eventData} isOpen={handleOpen} timelineStarted={started} isAbsent={isAbsent()}/>
                                </Box>
                            </Grid>
                        </Box>
                    </Fade>
                )}
            </Popper>
            <ConfirmDeleteDialog />
        </>
    );
}

export default observer(EventPopper);
