import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, InputBase, Paper } from "@mui/material";
import { useState } from "react";

interface SearchBarProps {
    placeholder?: string;
    onSearch: (searchValue: string) => void;
    isRounded?: boolean;
    isLoading?: boolean;
}

function SearchBar({ placeholder, onSearch, isRounded = true, isLoading }: SearchBarProps) {
    const [query, setQuery] = useState("");
    const [showClear, setShowClear] = useState(false);

    const handleSearch = (query: string) => {
        if (query === "") {
            setQuery("");
            setShowClear(false);
            return;
        }
        setQuery(query);
        setShowClear(true);
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSearch(query);
    }

    return (
        <Box className="search-bar-container">
            <Paper
                className="search-bar-style"
                sx={{ borderRadius: isRounded ? "30px" : "4px" }}
                component="form"
                onSubmit={handleSubmit}>
                <InputBase
                    disabled={isLoading}
                    sx={{ ml: 1, pl: 1, flex: 1, fontSize: "14px", width: "90%" }}
                    placeholder={placeholder}
                    onChange={(e) => handleSearch(e.target.value)}
                    value={query}
                    endAdornment={showClear ?
                        <IconButton
                            sx={{ color: "#808080", position: "absolute", right: 0 }}
                            onClick={() => {
                                handleSearch("");
                                onSearch("");
                            }}
                        >
                            <CloseRoundedIcon />
                        </IconButton>
                        : null
                    }
                />
                <IconButton sx={{ p: '10px' }} aria-label="search" title="Search" type="submit">
                    <SearchIcon color="secondary" />
                </IconButton>
            </Paper>
        </Box >
    );
}

export default SearchBar;
