import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useStore } from "../../../../stores/store";
import SurveyForm from "./SurveyForm";
import { Container } from "@mui/material";
import SurveyLoader from "../../../../components/loaders/SurveyLoader";
import { observer } from "mobx-react-lite";
import { getDecodedToken } from "../../../../utils/jwtDecoder";
import { Survey } from "../../../../data/models/survey";
import { surveyLanguages } from "../../../../data/static/survey";
import SurveyCreator from "../Creator/SurveyCreator";

type Mode = "preview" | "form";
type View = "assessor" | "client" | "user" | "admin";

export interface SurveyContextType {
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
    baseSurvey: Survey | null;
    setBaseSurvey: (survey: Survey | null) => void;
    width: number;
}

export const SurveyContext = createContext<SurveyContextType>(
    {} as SurveyContextType
);

function SurveyInitializer() {
    const { surveyStore, institutionStore, authStore, sessionStore } = useStore();
    const {
        getSurvey,
        getTimelineSurvey,
        getSurveyResponses,
        resetSurvey,
        getSurveyPreview,
        saveError,
    } = surveyStore;
    const { getSession, getAssessorSession } = sessionStore;
    const { selectedUserInstitution } = institutionStore;
    const { user } = authStore;

    const [searchParams, setSearchParams] = useSearchParams();
    const { responseId, timelineId, surveyId, sessionId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const lang = params.get("lang");
    const [mode, setMode] = useState<Mode>("form");
    const [view, setView] = useState<View | null>(null);
    const [survey, setSurvey] = useState<Survey | null>(null);
    const [reload, setReload] = useState(false);
    const [baseSurvey, setBaseSurvey] = useState<Survey | null>(null);

    const [editMode, setEditMode] = useState<boolean>(false);
    const [width, setWidth] = useState(window.innerWidth)
    window.addEventListener('resize', function (event) {
        setWidth(window.innerWidth)
    });

    useEffect(() => {
        getSurveyData(lang ?? undefined);
        return () => {
            resetSurvey();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUserInstitution]);

    const reloadAnswers = async () => {
        if (!saveError) return;

        if (token && timelineId && surveyId) {
            try {
                setReload(true);
                const survey = await getTimelineSurvey(
                    timelineId,
                    surveyId,
                    sessionId!,
                    token,
                    lang ?? undefined
                );
                setSurvey(survey);
                const { clientId } = getDecodedToken(token);
                if (user && user.id !== clientId) {
                    setMode("preview");
                    setView("client");
                }
                setReload(false);
            } catch (error) {
                setReload(false);
                throw error;
            }
        }
    };

    const getSurveyData = async (language: string = "en") => {
        let updatedSearchParams = new URLSearchParams(searchParams.toString());
        updatedSearchParams.set("lang", language);
        setSearchParams(updatedSearchParams.toString());

        if (surveyId) {
            if (timelineId) {
                if (token) {
                    const { clientId } = getDecodedToken(token);
                    if (user && user.id !== clientId) {
                        navigate('/not-found');
                    }
                    const survey = await getTimelineSurvey(
                        timelineId,
                        surveyId,
                        sessionId!,
                        token,
                        language
                    );
                    setSurvey(survey);
                    if (user && user.id !== clientId) {
                        setMode("preview");
                        setView("client");
                    }
                } else {
                    if (sessionId) {
                        try {
                            if (!user) {
                                navigate('/not-found');
                                return;
                            }

                            if (!selectedUserInstitution) return;
                            // const isAssessor = selectedUserInstitution.roles.includes("Assessor");
                            // const session = isAssessor ? await getAssessorSession(sessionId) : await getSession(sessionId);

                            const session = await getAssessorSession(sessionId);

                            // const isAssessorForm =
                            //     session.assessorId === user?.id &&
                            //     (session.currentStep === "ASSESSMENT_OF_SUICIDE_ACTIVE" ||
                            //         session.assessmentOfSuicideSurveyId === surveyId || session.impairmentSurveyId === surveyId);


                            // if (session.assessorId !== user?.id && session.clientId !== user?.id) {
                            //     navigate("/not-found");
                            //     return;
                            // }

                            // const viewType = session.assessorId === user.id ? "assessor" : session.clientId === user?.id ? "client" : "user"
                            const viewType = "assessor";

                            const survey = await getTimelineSurvey(
                                timelineId,
                                surveyId,
                                sessionId!,
                                undefined,
                                language
                            );
                            setSurvey(survey);
                            // if (!isAssessorForm && session.clientId !== user?.id) {
                            //     navigate("/not-found");
                            //     return;
                            // }
                            setMode("form");
                            setView(viewType);
                        } catch (error: any) {
                            if (error.response.status === 403) navigate("/not-found");
                        }
                    } else {
                        const survey = await getSurveyPreview(
                            timelineId,
                            surveyId,
                            language
                        );
                        setSurvey(survey);
                        setMode("preview");
                        setView("user");
                    }
                }
            } else {
                const survey = await getSurvey(surveyId, language);
                setSurvey(survey);
                setMode("preview");
                setView("user");
            }
        } else if (responseId && selectedUserInstitution) {
            const survey = await getSurveyResponses(
                responseId,
                selectedUserInstitution.institutionId,
                language
            );
            setSurvey(survey);
            setMode("preview");
            setView("admin");
        }
    };

    if ((!token && !selectedUserInstitution) || !survey || reload)
        return (
            <Container maxWidth="md">
                <SurveyLoader />
            </Container>
        );

    return (
        <SurveyContext.Provider
            value={{ editMode, setEditMode, baseSurvey, setBaseSurvey, width }}
        >
            {editMode ? (
                <SurveyCreator
                    survey={survey}
                    mode={mode}
                    view={view ?? undefined}
                    onLanguageChange={getSurveyData}
                    reload={getSurveyData}
                    direction={
                        surveyLanguages.find((l) => l.code === lang ?? "en")?.direction
                    }
                />
            ) : (
                <SurveyForm
                    survey={survey}
                    mode={mode}
                    view={view ?? undefined}
                    onLanguageChange={getSurveyData}
                    reloadAnswers={reloadAnswers}
                    direction={
                        surveyLanguages.find((l) => l.code === lang ?? "en")?.direction
                    }
                />
            )}
        </SurveyContext.Provider>
    );
}

export default observer(SurveyInitializer);
