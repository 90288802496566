import {
    Box,
    CircularProgress,
    Grid,
    Typography,
} from "@mui/material";
import { FormInputText } from "../../../components/form/FormInputText";
import FormInputSelect from "../../../components/form/FormInputSelect";
import { residencePermitOptions } from "../../Settings/User/residencePermitOptions";
import { portalLanguages } from "../../../data/static/language";
import FormInputChipSelect from "../../../components/form/FormInputChipSelect";
import FormInputDate from "../../../components/form/FormInputDate";
import { countries } from "../../../data/static/countries";
import { RoundButton } from "../../../_styles/StyledButtons";
import { useForm, useFormState } from "react-hook-form";
import { useStore } from "../../../stores/store";
import { ClientBasicInfoDto } from "../../../data/models/client";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { combinedClientSchema } from "../../../_validators/schemas/clientForm.schema";
import { useParams } from "react-router-dom";
import { ResponsiblePersonDto, UserDetailsDto, UserStatus } from "../../../data/models/user";
import { setBasicInfoValues } from "../../../utils/helpers/clientHelper";
import { getUserRoleIds } from "../../../utils/helpers/userHelper";
import LoadingComponent from "../../../components/LoadingComponent";
import { FormInputNumPattern } from "../../../components/form/FormInputNumber";
import { getGenderOptions } from "../../../data/static/user";
import { useTranslation } from "react-i18next";
import { surveyLanguages } from "../../../data/static/survey";
import { getTimezoneOffset } from "../../../utils/utils";
import { getAccomodationOptions } from "../../../data/static/clients";
import FormInputCheckbox from "../../../components/form/FormInputCheckbox";
import ClientDetails from "./ClientDetails";


interface ClientBasicInfoProps {
    next: () => void;
    display: boolean;
}

const commOptions = [
    { value: "email", label: "Email" },
    { value: "phone", label: "Phone" },
]

function ClientBasicInfo({ next, display }: ClientBasicInfoProps) {
    const { t } = useTranslation();
    const genderOptions = getGenderOptions(t);
    const accomodationOptions = getAccomodationOptions(t);
    const {
        institutionStore,
        clientStore,
        roleStore,
        tenantStore,
        languageStore,
        userStore,
        masterDataStore
    } = useStore();
    const {
        getInstitutions,
        selectedUserInstitution,
        getResponsiblePeople,
        responsibleUserList
    } = institutionStore;
    const [hasAccount, setHasAccount] = useState<boolean>(false);
    const [registerWithAccount, setRegisterWithAccount] = useState(false);
    const {
        createClient,
        updateClient,
        loading,
        _clientCreateId,
        clientCreateId,
    } = clientStore;
    const { getRoles, roleList } = roleStore;
    const { selectedTenant } = tenantStore;
    const { getUser, loading: userLoading } = userStore;
    const { getLanguages, languageList, } = languageStore;
    const { getHealthInsuranceOptions, healthInsuranceOptionsList } = masterDataStore;
    const { id } = useParams();
    const [client, setClient] = useState<UserDetailsDto | null>(null);
    const { control, watch, handleSubmit, reset, setValue, clearErrors } = useForm({
        mode: "onChange",
        resolver: yupResolver(
            client
                ? combinedClientSchema(client?.hasAccount!, true)
                : combinedClientSchema(false, false)
        ),
    });

    const { isDirty } = useFormState({ control });
    const registerUser = watch("hasAccount");
    const contactPref = watch("preferredCommunicationChannel");
    const editMode = _clientCreateId !== null;

    useEffect(() => {
        const fetchData = async () => {
            if (selectedTenant) {
                await getRoles(selectedTenant.id);
                await getInstitutions(selectedTenant.id);
            }
            await getLanguages();
            await getHealthInsuranceOptions();
            if (selectedUserInstitution)
                await getResponsiblePeople(selectedUserInstitution?.institutionId!);
        };

        if (id && selectedTenant && !client) {
            getUser(id, selectedTenant.id, selectedUserInstitution?.institutionId!).then((user) => {
                clientCreateId(user.id!);
                setClient(user);
                setBasicInfoValues(user, setValue, getDefaultRoles(user));
                setValue("institutionId", selectedUserInstitution?.institutionId!);
            });
        } else {
            setBasicInfoValues(null, setValue, []);
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTenant, selectedUserInstitution]);

    useEffect(() => {
        clearErrors("userLanguages");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasAccount])

    const onSubmit = async (data: any) => {
        delete data.emailConfirmation;
        delete data.phoneNumberConfirmation;
        data.contactWithReferringAgency = data.contactWithReferringAgency === "true";

        let ahvNumber = null;
        if (data.ahvNumber) {
            ahvNumber = data.ahvNumber.match(/^756\.____\.____\.__|^$/) ? null : data.ahvNumber;
        }
        const setEmptyFieldsToNull = (obj: any) => {
            for (const key in obj) {
                if (typeof obj[key] === "object" && obj[key] !== null) {
                    setEmptyFieldsToNull(obj[key]);
                } else if (obj[key] === "") {
                    obj[key] = null;
                }
            }
        };

        setEmptyFieldsToNull(data);

        if (_clientCreateId && !isDirty) return next();

        const institutionId = selectedUserInstitution?.institutionId!;
        const tenantId = selectedTenant?.id!;
        const responsibleUsers: ResponsiblePersonDto[] = [];

        data.responsibleUsers.forEach((item: string | ResponsiblePersonDto) => {
            if (typeof item === 'object') {
                responsibleUsers.push({
                    responsibleUserId: item.responsibleUserId,
                    responsibleUserInstitutionId: item.responsibleUserInstitutionId,
                });
            }
            else {
                responsibleUsers.push({
                    responsibleUserId: item.split(':')[0],
                    responsibleUserInstitutionId: item.split(':')[1]
                });
            }
        });

        let birthdate;
        if (data.birthdate) {
            const tzOffset = getTimezoneOffset(data.birthdate) * 60 * 60000;
            birthdate = new Date(data.birthdate.getTime() + tzOffset);
        }

        const clientInfo: ClientBasicInfoDto = {
            ...data,
            birthdate,
            institutionsAssigned: [
                {
                    institutionId: institutionId,
                    roleIds: data.hasAccount
                        ? [Number(data.roleIds)] || [-1]
                        : [-1],
                },
            ],
            tenantId: tenantId,
            responsibleUsers: responsibleUsers,
            ahvNumber,
            zemisNumber: data.zemisNumber ? data.zemisNumber : null,
        };

        if (editMode || client) {
            await updateClient(clientInfo);
        } else {
            await createClient(clientInfo);
        }

        if (_clientCreateId || client) {
            const user = await getUser(_clientCreateId!, selectedTenant!.id!, selectedUserInstitution?.institutionId!);
            setClient(user);
            setBasicInfoValues(user, setValue, getDefaultRoles(user));
        }

        setRegisterWithAccount(clientInfo.hasAccount);

        // TODO: Check if it's safe to remove the commented lines
        // setValue("password", "");
        // setValue("confirmPassword", "");
        reset({}, { keepValues: true });
        next();
    };

    const getDefaultRoles = (user: UserDetailsDto) => {
        const roles = getUserRoleIds(
            selectedUserInstitution?.institutionId!,
            user!
        );
        if (roles.length > 0) {
            return roles.map(String);
        } else {
            return [];
        }
    };

    if (userLoading)
        return (
            <Box height="90vh" display="flex" justifyContent="center">
                <LoadingComponent />
            </Box>
        );

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ display: display ? "block" : "none" }}
        >
            <Grid container columnSpacing={1} marginTop="1rem" padding="1rem 3rem">
                <Grid item xs={12} lg={6}>
                    <FormInputText
                        name="firstName"
                        control={control}
                        label={`${t("USER_FIRST_NAME")} *`}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputText
                        name="lastName"
                        control={control}
                        label={`${t("USER_LAST_NAME")} *`}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputSelect
                        name="gender"
                        control={control}
                        label={`${t("USER_GENDER")}`}
                        options={genderOptions}
                        labelKey={"label"}
                        valueKey={"value"}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputDate
                        name="birthdate"
                        control={control}
                        label={`${t("USER_BIRTHDATE")} *`}
                        date={null}
                    />
                </Grid>
                <Grid item xs={6} lg={4}>
                    <FormInputCheckbox
                        name="isUnaccompaniedMinor"
                        control={control}
                        label={`${t("USER_MINOR")}`}
                        defaultValue={client?.isUnaccompaniedMinor ?? false}
                    />
                </Grid>
                <Grid item xs={6} lg={8}>
                    <FormInputCheckbox
                        name="isIlliterate"
                        control={control}
                        label={`${t("USER_ILLITERATE")}`}
                        defaultValue={client?.isIlliterate ?? false}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputText name="street" control={control} label={t("GENERAL_STREET_ADDRESS")} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputText name="number" control={control} label={t("GENERAL_STREET_NUMBER")} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputText name="zip" control={control} label={t("GENERAL_ZIP")} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputText name="city" control={control} label={t("GENERAL_CITY")} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputSelect
                        name="country"
                        control={control}
                        label={t("GENERAL_COUNTRY")}
                        options={countries}
                        labelKey={"name"}
                        valueKey={"code"}
                    />
                </Grid>
                <Grid item xs={12} >
                    <FormInputSelect
                        name="originCountry"
                        control={control}
                        label={t("ORIGIN_COUNTRY")}
                        options={countries}
                        labelKey={"name"}
                        valueKey={"code"}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputSelect
                        name="accomodation"
                        control={control}
                        label={`${t("CLIENT_ACCOMODATION")}`}
                        options={accomodationOptions}
                        labelKey={"label"}
                        valueKey={"value"}
                        hasClearOption
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputSelect
                        name="preferredCommunicationChannel"
                        control={control}
                        label={`${t("CLIENT_CONTACT_PREF")} *`}
                        options={commOptions}
                        labelKey={"label"}
                        valueKey={"value"}
                        helperText={t("CLIENT_CONTACT_HELP")}
                        onSelect={() => { clearErrors("phoneNumber"); clearErrors("email") }}

                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputText
                        name="phoneNumber"
                        helperText="e.g. +41xxxxxxxxx"
                        control={control}
                        label={`${t("WHATSAPP_NUMBER")} ${contactPref === "phone" ? "*" : ""}`}
                    />
                    {!client && <FormInputText
                        name="phoneNumberConfirmation"
                        control={control}
                        label={`${t("GENERAL_WHATSAPP_NUMBER_CONFIRM")} ${contactPref === "phone" ? "*" : ""}`}
                    />}
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputText
                        name="email"
                        control={control}
                        label={`${t("GENERAL_EMAIL")}  ${registerUser || contactPref === "email" ? "*" : ""}`}
                    />
                    {!client && <FormInputText
                        name="emailConfirmation"
                        control={control}
                        label={`${t("GENERAL_EMAIL_CONFIRM")}  ${registerUser || contactPref === "email" ? "*" : ""}`}
                    />}
                </Grid>
                {/* INFO: Disabled as per request. Will be enabled in the future implementations 
                {((client && !client.isAdmin) || !client) && (
                    <Grid item xs={12}>
                        <Box width="100%">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={registerUser ?? hasAccount}
                                        onChange={(e) => {
                                            setValue("hasAccount", e.target.checked);
                                            setHasAccount(e.target.checked);
                                        }}
                                        name="hasAccount"
                                    />
                                }
                                label={
                                    <Typography fontSize="14px">{t("CLIENT_REGISTER")}</Typography>
                                }
                            />
                        </Box>
                    </Grid>
                )} */}
                {(client &&
                    (client.isAdmin || client.hasAccount)) || registerWithAccount ? null : !registerUser ? null : (
                        <Grid item xs={12} lg={6}>
                            <FormInputText
                                name="password"
                                control={control}
                                label={`${t("USER_PASSWORD")} *`}
                                type="password"
                                helperText={t("PASSWORD_POLICY")}

                            />
                        </Grid>
                    )}
                {(client &&
                    (client.isAdmin || client.hasAccount)) || registerWithAccount ? null : !registerUser ? null : (
                        <Grid item xs={12} lg={6}>
                            <FormInputText
                                name="confirmPassword"
                                control={control}
                                label={`${t("USER_CONFIRM_PASSWORD")} *`}
                                type="password"
                            />
                        </Grid>
                    )}
                <Grid item xs={12} sx={{ display: "none" }}>
                    <FormInputText
                        name="institutionId"
                        control={control}
                        label={t("CLIENT_TENANT")}
                        readOnly={true}
                    />
                </Grid>
                {client && (client.isAdmin || client.hasAccount)
                    ? null
                    : registerUser && (
                        <Grid item xs={12} lg={6}>
                            <FormInputSelect
                                name="roleIds"
                                control={control}
                                label={`${t("SETTINGS_ROLES_NAME")} *`}
                                options={roleList.filter(r => r.name === 'Client').map((r) => {
                                    return {
                                        id: r.id.toString(),
                                        name: r.name,
                                    };
                                })}
                                labelKey={"name"}
                                isDisabled
                                valueKey={"id"}
                                defaultValue={roleList.filter(r => r.name === 'Client')[0]?.id?.toString() ?? ""}
                            />
                        </Grid>
                    )}
                {registerUser && (
                    <Grid item xs={12} lg={6}>
                        <FormInputSelect
                            name="platformLanguageAlpha2"
                            control={control}
                            label={`${t("USER_PORTAL_LANG")} *`}
                            options={portalLanguages}
                            valueKey={"alpha2"}
                            labelKey={"name"}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    {languageList.length > 0 && (
                        <FormInputChipSelect
                            name="userLanguages"
                            control={control}
                            label={`${t("USER_LANG")} ${registerUser ? "*" : ""}`}
                            options={languageList}
                            labelKey="name"
                            valueKey="alpha2"
                            search={true}
                            defaultValues={client?.userLanguages.map((l) => l.alpha2) || []}
                        />
                    )}
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputSelect
                        name="communicationLanguage"
                        control={control}
                        label={`${t("USER_COMM_LANG")} *`}
                        options={surveyLanguages}
                        labelKey="name"
                        valueKey="code"
                        defaultValue={client?.communicationLanguage || "en"}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputNumPattern
                        name="zemisNumber"
                        control={control}
                        label={t("CLIENT_ZEMIS")}
                        format="########.#"
                    />
                </Grid>
                <Grid item xs={12} >
                    {healthInsuranceOptionsList.length > 0 && (
                        <FormInputSelect
                            name="healthInsurance"
                            control={control}
                            label={`${t("CLIENT_HEALTH_INSURANCE")}`}
                            options={healthInsuranceOptionsList}
                            valueKey={"value"}
                            labelKey={"name"}
                            hasClearOption={true}
                        />)}
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputNumPattern
                        name="ahvNumber"
                        control={control}
                        label={t("CLIENT_AHV")}
                        format="756.####.####.##"
                        emptyFormatting
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputChipSelect
                        name="responsibleUsers"
                        control={control}
                        label={`${t("USER_RESPONSIBLE_PEOPLE")} *`}
                        options={[
                            ...responsibleUserList
                                .filter(u => u.institutionId === selectedUserInstitution?.institutionId)
                                .map((u) => {
                                    return {
                                        userInstitutionName: `${u.firstName} ${u.lastName} - ${u.roles} (${u.institutionName})`,
                                        uniqueId: `${u.id}:${selectedUserInstitution?.institutionId}`,
                                        disabled: u.status !== UserStatus.ACTIVE
                                    }
                                })
                                .sort((a, b) => a.userInstitutionName.localeCompare(b.userInstitutionName))
                        ]}
                        search={true}
                        labelKey="userInstitutionName"
                        valueKey="uniqueId"
                        defaultValues={client?.responsibleUsers.map(element => {
                            return element.responsibleUserId + ':' + element.responsibleUserInstitutionId;
                        })}
                    />
                </Grid>
                <Grid item xs={6} lg={6}>
                    <FormInputSelect
                        name="residenceStatus"
                        control={control}
                        label={t("USER_RESIDENCE_PERMIT")}
                        options={residencePermitOptions}
                        valueKey={"id"}
                        labelKey={"name"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormInputText
                        name="notes"
                        control={control}
                        label={t("GENERAL_NOTES")}
                        rows={3}
                    />
                </Grid>
                <ClientDetails control={control} watch={watch}></ClientDetails>
                <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <RoundButton
                        type="submit"
                        variant="contained"
                        sx={{ mt: 2, mb: 2 }}
                        disabled={loading}
                        title="Submit"
                        data-testid="submit-button"
                    >
                        {loading
                            ? (
                                <CircularProgress size={25} />
                            )
                            : (
                                editMode
                                    ? <Typography fontSize="13px">{t("GENERAL_UPDATE")}</Typography>
                                    : <Typography fontSize="13px">{t("GENERAL_SUBMIT")}</Typography>
                            )}
                    </RoundButton>
                </Grid>
            </Grid>
        </Box >
    );
}

export default observer(ClientBasicInfo);
